import React, { useState } from "react";
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import styles from "../../assests/styles/views/auth/login";
import LoadingButton from "../../components/ButtonWithLoading";
import { Formik } from "formik";
import checkEmail from "../../helpers/checkEmailFormat";
import { userLogin, setUser, forgetPassword } from "../../actions";
import { useDispatch } from "react-redux";
import checkErrors from "../../utils/responseUtils";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { FcLock } from "react-icons/fc";
import { getTranslation } from "../../heplers/translationHelper";

const LoginForm = ({ history }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} className={classes.root}>
        <div className={classes.paper}>
          <FcLock style={{ color: "blue" }} className={classes.avatar} />
          <Typography className={classes.form} component="h1" variant="h5">
            {getTranslation(
              "Forget Password",
              "Mot de passe oublié",
              "Passwort vergessen"
            )}
          </Typography>

          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (checkEmail(values.email)) {
                errors.email = "Invalid email address";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(forgetPassword(values)).then((res) => {
                const errors = checkErrors(res);
                if (!errors) {
                  enqueueSnackbar(
                    "Please check your email for password change",
                    { variant: "success" }
                  );

                  setSubmitting(false);
                } else {
                  setSubmitting(false);
                  enqueueSnackbar("Something went wrong", { variant: "error" });
                  setAlert(true);
                }
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      id="email"
                      label={getTranslation("Email", "E-mail", "Email")}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <LoadingButton
                      color="primary"
                      fullWidth={true}
                      label={getTranslation(
                        "Submit",
                        "Soumettre",
                        "Einreichen"
                      )}
                      loading={isSubmitting}
                      type="submit"
                      variant="contained"
                      //   className={classes.submit}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
          >
            <Link to="/login" variant="h6" className={classes.link}>
              {getTranslation("Back to Login","Back to Login","Back to Login")}
            </Link>
          </Box>
        </div>
      </Paper>
    </Container>
  );
};

export default withRouter(LoginForm);
