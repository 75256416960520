import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Get, Post } from "../../actions";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import TableComponent from "../../components/table";
import { Get_AllPendingSMS_URL, Get_ClearAllSMS_URL, Post_DeleteSelectedSMS_URL } from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";

const columns = [
    {
        id: "phone",
        numeric: false,
        disablePadding: true,
        label: getTranslation("Phone", "Phone", "Phone"),
    },
    {
        id: "message",
        numeric: false,
        disablePadding: true,
        label: getTranslation("Message", "Message", "Message"),
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "",
    }
];

const PendingSMS = () => {


    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const [isDeleting, setDeleting] = useState(false)
    useEffect(() => {
        Get(
            {},
            Get_AllPendingSMS_URL,
            null,
            resp => {
                setRows(resp.data)
            },
            error => {

            }
        )
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClearSMS = () => {
        setSubmitting(true)
        Get(
            {},
            Get_ClearAllSMS_URL,
            null,
            resp => {
                setSubmitting(false)

                enqueueSnackbar("All pending SMS cleared.", { variant: "success" })
            },
            error => {
                enqueueSnackbar("System was not able to clear.", { variant: "error" })
                setSubmitting(false)

            }
        )
    }

    const handleDeleteSMS = () => {
        setDeleting(true)
        console.log("ajkhdajkshdasd", selectedRows);
        Post(
            selectedRows,
            Post_DeleteSelectedSMS_URL,
            null,
            resp => {
                enqueueSnackbar("All selected SMS are removed.", { variant: 'success' })
                setSelectedRows([])
                setDeleting(false)
                Get(
                    {},
                    Get_AllPendingSMS_URL,
                    null,
                    resp => {
                        setRows(resp.data)
                    },
                    error => {
        
                    }
                )
            },
            error => {
                enqueueSnackbar("System was not able to remove try again.", { variant: 'error' })
                setDeleting(false)

            }
        )
    }


    return (
        <div>
            <Box
                mb={4}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end">

                <ButtonWithLoading
                    label="Clear All Pending SMS"
                    loading={isSubmitting}
                    onClick={() => handleClearSMS()}
                    color="primary"
                    type="submit"
                    variant="contained"
                />
                <ButtonWithLoading
                    disabled={selectedRows.length == 0}
                    loading={isDeleting}
                    style={{ marginLeft: "10px" }}
                    label="Delete Selected SMS"
                    onClick={() => handleDeleteSMS()}
                    color="primary"
                    type="submit"
                    variant="contained"
                />
            </Box>

            <TableComponent
                auto
                title={getTranslation("Pending SMS", "Pending SMS", "Pending SMS")}
                colums={columns}
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                rows={rows}
                enableCheckbox
                onSelectionChange={(selected)=>setSelectedRows(selected)}
                selected={selectedRows}
                onSelectionChecked ={()=>{}}
                onSelectionUnChecked  ={()=>{}}
                // viewDelete
                // onDelete={(row) => handleDeleteSMS(row)}
            />
        </div>
    );
};

export default PendingSMS;
