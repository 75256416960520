import axios from "../helpers/apiHelper";
import {
  LOADING_CUSTOMERS,
  CUSTOMERS_DATA,
  CUSTOMERS_ERRORS,
  MY_APP_DATA,
} from "./types";

//End Points
// const RegisterCustomerAppEndPoint = "Auth/Login";
const RegisterCustomerEndPoint = "Customers/RegisterCustomer";
const GetAllCustomersEndPoint = "Customers/GetAllCustomers";
const GetMyAppsEndPoint = "Customers/GetCustomerApps";
const UpdateUserProfileEndPoint = "Auth/UpdateUserProfile";
const ChangePasswordEndPoint = "Auth/ChangePassword";
const ChangeEmailEndPoint = "Auth/UpdateEmail";

export const RegisterCustomer = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(
      RegisterCustomerEndPoint,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GetAllCustomers = (query, history) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CUSTOMERS });
    const response = await axios(history).get(GetAllCustomersEndPoint, {
      params: query,
    });
    dispatch({ type: CUSTOMERS_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: CUSTOMERS_ERRORS, payload: error.response });
  }
};

export const GetMyApps = (history) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CUSTOMERS });
    const response = await axios(history).get(GetMyAppsEndPoint);
    dispatch({ type: MY_APP_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: CUSTOMERS_ERRORS, payload: error.response });
  }
};

export const UpdateCustomerProfile = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(
      UpdateUserProfileEndPoint,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ChangeCustomerPassword =
  (payload, history) => async (dispatch) => {
    try {
      const response = await axios(history).post(
        ChangePasswordEndPoint,
        payload
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

export const ChangeAdminEmail = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(ChangeEmailEndPoint, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
