import React, { useState } from "react";
import { TextField, Grid, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import { getTranslation } from "../../heplers/translationHelper";

const ManageKeys = ({ keys, setKeys }) => {
  const handleDeleteKey = (key) => {
    setKeys(keys.filter((el) => el !== key));
  };

  return (
    <div>
      <Grid container spacing={2}>
        {keys.map((key, index) => {
          return (
            <>
              <Grid item>
                <TextField
                  key={index}
                  variant="outlined"
                  size="small"
                  label={getTranslation("Title", "Titre", "Titel")}
                  value={key.title}
                />
              </Grid>
              <Grid item>
                <TextField
                  key={index}
                  variant="outlined"
                  size="small"
                  label={getTranslation("Key", "Cl�", "Schl�ssel")}
                  value={key.key}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleDeleteKey(key)}
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </>
          );
        })}
      </Grid>
      {/* {addNewKey ? (
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                label= {getTranslation("Title","Titre","Titel")}
                value={key.title}
                onChange={(e) => setKey({ ...key, title: e.target.value })}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                label=  {getTranslation("Key","Cl�","Schl�ssel")}
                value={key.key}
                onChange={(e) => setKey({ ...key, key: e.target.value })}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleAddKey}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        ) : null} */}
      <Formik
        initialValues={{ key: "", title: "", value: "" }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Required"),
          key: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          setKeys(keys.concat(values));

          actions.setFieldValue("title", "");
          actions.setFieldValue("key", "");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  label={getTranslation("Title", "Titre", "Titel")}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  size="small"
                  label={getTranslation("Key", "Cl�", "Schl�ssel")}
                  name="key"
                  value={values.key}
                  onChange={handleChange}
                  error={touched.key && Boolean(errors.key)}
                  helperText={touched.key && errors.key}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ManageKeys;
