import React from "react";
import {Typography,Box} from "@material-ui/core";
import {UploadImage} from "../../actions";
import {useDispatch} from "react-redux"


const ImageUploadCard =(props)=> {
  const dispatch = useDispatch();

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload=()=>{
      dispatch(
        UploadImage({
          formFile: reader.result.replace(/^data:image\/[a-z]+;base64,/, ""),
          fileName: file.name,
        })
      ).then(res=>props.image(res));
    }
    

  };
  
    return (
      <Box display="flex" alignItems="center">
        <Typography >{props.title}</Typography>
        <Box pl={5}>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadClick}
          />
        </Box>
      </Box>
    );
  
}

export default (ImageUploadCard);
