 import { SET_USER, SET_USER_BY_ID} from "../actions/types";
 const initState={
   loading:false,
     user:null,
     error:null
 }

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_BY_ID:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return { ...state };
  }
};

export default userReducer;