import { makeStyles,colors} from "@material-ui/core";


export default makeStyles((theme) => ({
  header: {
    backgroundColor: colors.grey[200],
    color: theme.palette.primary.main
  },
  title: {
    fontSize: "18px",
    padding: "10px 0px",
  },
  content: {
    padding: "30px",
    textAlign: "center",
  },
  price: {
    fontSize: "22px",
    padding: "10px 0px",
  },
  months: { fontSize: "16px", padding: "10px 0px" },
  year: { fontSize: "16px", padding: "10px 0px" },
  container: {
    marginTop: theme.spacing(10),
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  icon: {
    fontSize: "44px",
    color: theme.palette.error.main,
  },
  text: {
    fontSize: "24px",
    marginTop: "10px",
  },
  features: {
    overflowY: "auto",
    height:"170px"
  },
  description: {

    textAlign: "center",
    //whiteSpace: "nowrap",
   
    height:"178px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

