import axios from "../helpers/apiHelper";
import {URL} from "../constants"

//End Points
const ImageUploadEndPoint = "FileManager/Upload";
const GetImageEmdPoint = "FileManager/Get";


export const UploadImage = (payload) => async dispatch => {
  try {
    const response = await axios().post(ImageUploadEndPoint, payload);
   return response.data;
  } catch (error) {
    return error.response;
  }
};


export const GetImage = (fileName) => {
 
    return URL + GetImageEmdPoint + `?fileName=${fileName}`;
 
};