import React from 'react'
import { useHistory,useParams } from 'react-router-dom';

export const RedirectToMyApp=()=> {
    const history=useHistory();
    var {appId}=useParams();
    history.push("/myapps",{openDialog:true,appId:appId});

  return (
    <div></div>
  )
}
