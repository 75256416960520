import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  FormControl,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DialogActions from "../../components/DialogActions";
import DialogContent from "../../components/DialogContent";
import LoadingButton from "../../components/ButtonWithLoading";
import ReactQuill from "react-quill";
import {
  InsertNewPackage,
  UpdatePackage,
  GetAllPackags,
  GetAppById,
} from "../../actions";
import checkResponse from "../../utils/responseUtils";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { getTranslation } from "../../heplers/translationHelper";

const packagePeriods = [
  {
    id: 1,
    title: getTranslation("Monthly", "Mensuelle", "Monatlich"),
  },
  {
    id: 2,
    title: getTranslation("Yearly", "Annuelle", "Jährlich"),
  },
];

const AddNewPackage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [isLifeTime, setIsLifeTime]=useState(false)
  const editApp = props.editApp;
  const [applicationKeys, setApplicationKeys] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  //const editApp = editApp
  const { appId } = useParams();
  const packageData = useSelector((state) => state.packages.editPackage);
  useEffect(() => {
    dispatch(GetAppById(appId));
  }, [appId]);
  useEffect(() => {
    if (packageData && editApp) {
      const keys = editApp?.appKeys;
      packageData?.appKeys.forEach((key) => {
        var index = keys.findIndex((x) => x.key == key.key);
        if (index >= 0) {
          keys[index].value = key.value;
        }
      });
      setApplicationKeys(keys);
    } else if (editApp) {
      setApplicationKeys(editApp?.appKeys);
    }
  }, [packageData, editApp]);

  const handleChangeKeys = (e, item, index) => {
    let newKeysArray = applicationKeys;
    newKeysArray[index].value = e.target.value;
    setApplicationKeys([...newKeysArray]);
  };

  const initialValue = {
    title: "",
    description: "",
    features: "",
    lifeTimePrice: 0,
    price: 0,
    internal: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    deadline: "",
    trialDays: 0,
    isMonthly: false,
    isYearly: false,
    isLifeTime: false,
  };

  const basicValidationSchema = Yup.object().shape({});
  const defaultValue = packageData ? packageData : initialValue;
  return (
    <Formik
      enableReinitialize
      initialValues={defaultValue}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          packageData
            ? UpdatePackage(
                { ...values, appId: editApp.id, appKeys: applicationKeys },
                history
              )
            : InsertNewPackage(
                { ...values, appId: editApp.id, appKeys: applicationKeys },
                history
              )
        ).then((res) => {
          const errors = checkResponse(res);
          if (!errors) {
            packageData
              ? enqueueSnackbar("Package Updated", { variant: "success" })
              : enqueueSnackbar("Package Added ", { variant: "success" });
            dispatch(
              GetAllPackags(
                { appId: editApp?.id, pageNumber: 0, pageSize: 10 },
                history
              )
            );
            setSubmitting(false);
            props.onClose(false);
          } else {
            errors?.map((error) =>
              enqueueSnackbar(error.message, { variant: "error" })
            );
            setSubmitting(false);
          }
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        getFieldProps,
      }) => (
        <Form>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation("Title", "Titre", "Titel")}
                  name="title"
                  value={values.title}
                  {...getFieldProps("title")}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              {/* Life time  */}
              <Grid item sm={12} md={6} lg={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={packageData}
                        checked={values.isLifeTime}
                        onChange={(e, check) =>
                          setFieldValue("isLifeTime", check)
                        }
                        name="isLifeTime"
                        color="primary"
                      />
                    }
                    label={getTranslation(
                      "Life Time Package",
                      "Permanent Emballer",
                      "Lebenszeit Paket"
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  disabled={!values.isLifeTime}
                  id="lifeTimePrice"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Life time price",
                    "Prix ​​à vie",
                    "Lebenslanger Preis"
                  )}
                  name="lifeTimePrice"
                  type="number"
                  {...getFieldProps("lifeTimePrice")}
                  value={values.lifeTimePrice}
                  onChange={handleChange}
                  error={touched.lifeTimePrice && Boolean(errors.lifeTimePrice)}
                  helperText={touched.lifeTimePrice && errors.lifeTimePrice}
                />
              </Grid>
              {/* Monthly */}
              <Grid item sm={12} md={6} lg={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={packageData}
                        checked={values.isMonthly}
                        onChange={(e, check) =>
                          setFieldValue("isMonthly", check)
                        }
                        name="isMonthly"
                        color="primary"
                      />
                    }
                    label={getTranslation(
                      "Monthly Package",
                      "Permanent Emballer",
                      "Monatliches Paket"
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  disabled={!values.isMonthly || packageData}
                  id="monthlyPrice"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Monthly price",
                    "Prix ​​mensuel",
                    "Monatlicher Preis"
                  )}
                  name="monthlyPrice"
                  {...getFieldProps("monthlyPrice")}
                  type="number"
                  value={values.monthlyPrice}
                  onChange={handleChange}
                  error={touched.monthlyPrice && Boolean(errors.monthlyPrice)}
                  helperText={touched.monthlyPrice && errors.monthlyPrice}
                />
              </Grid>
              {/* Yearly */}
              <Grid item sm={12} md={6} lg={6}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={packageData}
                        checked={values.isYearly}
                        onChange={(e, check) =>
                          setFieldValue("isYearly", check)
                        }
                        name="isYearly"
                        color="primary"
                      />
                    }
                    label={getTranslation(
                      "Yearly Package",
                      "Permanent Emballer",
                      "Jährliches Paket"
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  disabled={!values.isYearly || packageData}
                  id="yearlyPrice"
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Yearly price",
                    "Prix ​​annuel",
                    "Jährlicher Preis"
                  )}
                  name="yearlyPrice"
                  {...getFieldProps("yearlyPrice")}
                  type="number"
                  value={values.yearlyPrice}
                  onChange={handleChange}
                  error={touched.yearlyPrice && Boolean(errors.yearlyPrice)}
                  helperText={touched.yearlyPrice && errors.yearlyPrice}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.internal}
                        onChange={handleChange}
                        name="internal"
                        color="primary"
                      />
                    }
                    label={getTranslation(
                      "For internal use only",
                      "Pour usage interne uniquement",
                      "Nur für interne Benutzung"
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation("Deadline", "Date limite", "Termin")}
                  name="deadline"
                  type="number"
                  value={values.deadline}
                  onChange={handleChange}
                  error={touched.deadline && Boolean(errors.deadline)}
                  helperText={touched.deadline && errors.deadline}
                />
              </Grid>

              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Trial Days",
                    "Journées d'essai",
                    "Schnuppertage"
                  )}
                  name="trialDays"
                  type="number"
                  value={values.trialDays}
                  onChange={handleChange}
                  error={touched.trialDays && Boolean(errors.trialDays)}
                  helperText={touched.trialDays && errors.trialDays}
                />
              </Grid>

              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Description",
                    "Description",
                    "Beschreibung"
                  )}
                  multiline
                  rows={3}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>

              <Grid item sm={12} md={12} lg={12}>
                <Typography variant="body1" color="textPrimary">
                  {getTranslation("Criteria", "Critère", "Kriterien")}
                </Typography>
                <Grid container spacing={1}>
                  {applicationKeys?.map((item, index) => {
                    return (
                      <>
                        <Grid item>
                          <TextField
                            key={index}
                            variant="outlined"
                            size="small"
                            label={item.title}
                            disabled
                          />
                        </Grid>

                        <Grid item>
                          <TextField
                            key={`${index}index`}
                            variant="outlined"
                            size="small"
                            label={item?.title}
                            value={item?.value}
                            onChange={(e) => handleChangeKeys(e, item, index)}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid item sm={12} md={12} lg={12}>
                <Typography variant="body1" color="textPrimary">
                  {getTranslation("Features", "Caractéristiques", "Merkmale")}
                </Typography>
                <ReactQuill
                  style={{ height: 200 }}
                  value={values.features}
                  onChange={(value) => setFieldValue("features", value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              color="primary"
              label={packageData ? "Update" : "Add"}
              loading={isSubmitting}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewPackage;
