import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Box } from "@material-ui/core";
import AppCard from "./MyAppsCard";
import { useHistory, Link } from "react-router-dom";
import { GetMyApps } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import { MultiOrganizationRequest } from "../market/components";
import { useLocation } from "react-router-dom";

const MyAppsManagement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const myApps = useSelector((state) => state.customers.myApps);
  const [isOpen, setIsOpen] = useState(false);
  const [appId, setAppId] = useState();
  useEffect(() => {
    dispatch(GetMyApps());
    if (location.state?.openDialog) {
      setAppId(location.state?.appId);
      setIsOpen(true);
    }
    //window?.history?.replaceState("/");
  }, []);
  return (
    <>
      <MultiOrganizationRequest openDialog={isOpen} appId={appId} />
      <Grid container spacing={3}>
        {myApps.length > 0 ? (
          myApps.map((app) => (
            <Grid item sm={12} md={4} lg={4}>
              <AppCard data={app} />
            </Grid>
          ))
        ) : (
          <Container maxWidth='xs'>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              height='70vh'
              flexDirection='column'
            >
              <Typography variant='h4'>
                {getTranslation(
                  "No registered apps found",
                  "Aucune application enregistrée trouvée",
                  "Keine registrierten Apps gefunden"
                )}
              </Typography>
              <Box mt={2}>
                <Typography variant='h6'>
                  {getTranslation(
                    "You can register app",
                    "Vous pouvez enregistrer l'application",
                    "Sie können die App registrieren"
                  )}{" "}
                  <Link to='/market'>
                    {getTranslation("here", "ici", "hier")}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Container>
        )}
      </Grid>
    </>
  );
};

export default MyAppsManagement;
