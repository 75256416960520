import React from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import theme from "../theme";

const ButtonWithLoading = (props) => {
  const { loading, onClick, label } = props;
  return (
    <Button
      onClick={onClick}
      startIcon={loading ? <CircularProgress size={24} thickness={3} /> : null}
      disabled={loading}
      style={{
        margin: "5px",
        "&:hover": {
          backgroundColor: "rgb(33,87,116)",
        },
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default ButtonWithLoading;
