import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { Get, MakePayment, Post } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import checkErrors from "../../../utils/responseUtils";
import LoadingButton from "../../../components/ButtonWithLoading";
import securelogo from "../../../assests/images/Poweredbystripe.png";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import {
  GetCustomerAppEndPoint,
  Get_CustomerAppPackage_URL,
  Get_InvoiceDraft_URL,
  Post_ConfirmPayment_URL,
  Post_SummarizePayment_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { EuropeanCountries } from "./CreateBillingInfoDialog";

const CheckoutForm = ({ installerModel, onPreviousStep, ...rest }) => {
  const history = useHistory();
  const props = rest;
  const [errorMessage, setErrorMessage] = useState(null);
  const { loading, errors, packageData } = useSelector(
    (state) => state.packages
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState({ error: false, errorMessage: "" });
  const { customerRegisteredAppId } = useParams();
  const [customerApp, setCustomerApp] = useState({});

  useEffect(() => {
    Get(
      { customerAppId: customerRegisteredAppId },
      GetCustomerAppEndPoint,
      null,
      (resp) => {
        const european = EuropeanCountries[installerModel?.billingInfo.country];
        const total = resp?.data?.amount;
        let vat21Value = 0;
        resp.data.currentVat = 0;
        let amountWithoutVat = total;

        // Case 1: Normal Regulation and Customer has valid vat
        // Apply Belgian => 21% and others 0%

        if (process.env.REACT_APP_VATREGULATIONTYPE_URL == "normal") {
          if (installerModel?.billingInfo.isVATVerified == true) {
            if (european && european.key == "BE") {
              vat21Value = total * (european.vat / 100);
              amountWithoutVat = total;
              resp.data.currentVatValue = vat21Value;
              resp.data.currentVat = european.vat;
            } else {
              resp.data.currentVat = 0;
              resp.data.currentVatValue = vat21Value;
            }
          } else {
            // Case 1: Normal Regulation and Customer has not valid vat
            if (european) {
              vat21Value = total * (european.vat / 100);

              amountWithoutVat = total;
              resp.data.currentVatValue = vat21Value;
              resp.data.currentVat = european.vat;
            } else {
              resp.data.currentVat = 0;
              resp.data.currentVatValue = vat21Value;
            }
          }
        } else {
          if (installerModel?.billingInfo.isVATVerified == true) {
            if (european && european.key == "BE") {
              vat21Value = total * (european.vat / 100);
              amountWithoutVat = total;
              resp.data.currentVatValue = vat21Value;
              resp.data.currentVat = european.vat;
            } else if (european) {
              resp.data.currentVat = 0;
              amountWithoutVat = total;
              resp.data.currentVatValue = 0;
            } else {
              resp.data.currentVat = 0;
              resp.data.currentVatValue = vat21Value;
            }
          } else {
            if (european) {
              vat21Value = total * 0.21;
              amountWithoutVat = total;
              resp.data.currentVatValue = vat21Value;
              resp.data.currentVat = 21;
            } else resp.data.currentVat = 0;
            resp.data.currentVatValue = vat21Value;
          }
        }
        resp.data.totalToPay = resp?.data?.currentVatValue + amountWithoutVat;
        resp.data.amountWithoutVat = amountWithoutVat;
        resp.data.maintenanceCharges = resp.data.totalToPay * 0.1;
        setCustomerApp(resp?.data);
      },
      (error) => {
        console.log("lkdhfgklhdflgf", error);
      }
    );
  }, []);

  const handleSubmit = async (e) => {
    console.log("payment");
    e.preventDefault();
    setSubmitting(true);
    const cardElementRef = props.elements.getElement("cardNumber");
    try {
      let paymentMethod = await props.stripe.createPaymentMethod({
        type: "card",
        card: cardElementRef,
      });
      let client_secret = "";

      if (paymentMethod) {
        dispatch(
          MakePayment(
            {
              ...installerModel,
              countryCode: installerModel?.billingInfo?.countryCode,
              amount: customerApp?.totalToPay,
              paymentToken: paymentMethod?.paymentMethod?.id,
              customerRegisteredAppId: parseInt(customerRegisteredAppId),
              vat: customerApp?.currentVat,
            },
            history
          )
        ).then(async (res) => {
          console.log("asjkdhasjkdhaskdhkasjhd res ", res);
          if (res.data.status == "3ds") {
            const confirmPayment = await props.stripe.confirmCardPayment(
              res.data?.data?.client_secret,
              { payment_method: { card: cardElementRef } }
            );
            if (confirmPayment.paymentIntent.status == "succeeded") {
              await Post(
                {
                  ...installerModel,
                  countryCode: installerModel?.billingInfo?.countryCode,
                  amount: customerApp?.totalToPay,
                  paymentToken: paymentMethod?.paymentMethod?.id,
                  customerRegisteredAppId: parseInt(customerRegisteredAppId),
                },
                Post_ConfirmPayment_URL,
                null,
                (status) => {
                  enqueueSnackbar(status.data, { variant: "success" });
                  // history.push("/myapps");
                  // window.location.href = localStorage.getItem("backUrl");
                  const url = localStorage.getItem("backUrl");
                  const id = localStorage.getItem("userAppCustumerId_");
                  const fullUrl = `${url}?id=${id}`;
                  window.location.href = fullUrl;
                  setSubmitting(false);
                },
                (error) => {
                  enqueueSnackbar(error.data, { variant: "error" });
                  setSubmitting(false);
                }
              );
            } else {
              enqueueSnackbar(
                "We were not able to make the payments using this card, Please try another card or contact your bank for more details about the issue.",
                { variant: "error" }
              );

              setSubmitting(false);
            }
          } else {
            enqueueSnackbar(res.data.message, { variant: "success" });
            //  history.push("/myapps");
            //  window.location.href = localStorage.getItem("backUrl");
            const url = localStorage.getItem("backUrl");
            const id = localStorage.getItem("userAppCustumerId_");
            const fullUrl = `${url}?id=${id}`;
            window.location.href = fullUrl;
            setSubmitting(false);
          }
        });
      }
    } catch (error) {
      console.log("asjkdhasjkdhaskdhkasjhd", error);
      enqueueSnackbar("Invalid card information", { variant: "error" });
    }
  };

  return (
    <Card>
      {customerApp?.amountWithoutVat ? (
        <>
          <form onSubmit={handleSubmit}>
            <CardContent>
              {isError.error ? (
                <Alert severity='error'>{isError.errorMessage}</Alert>
              ) : null}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card
                    style={{
                      height: "650px",
                    }}
                  >
                    <CardHeader
                      title={getTranslation(
                        "Summary",
                        "Résumé",
                        "Zusammenfassung"
                      )}
                    />
                    <CardContent>
                      <Typography variant='h4'>
                        {getTranslation("Package", "Accueil", "Startseite")}
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {getTranslation(
                                "Package Name",
                                "Nom du paquet",
                                "Paketnamen"
                              )}
                            </TableCell>
                            <TableCell>
                              {customerApp?.appTitle +
                                " | " +
                                customerApp?.packageName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getTranslation("Price", "Prix", "Preis")}
                            </TableCell>
                            <TableCell>
                              {customerApp?.amount.toFixed(2)} &euro;
                            </TableCell>
                          </TableRow>
                          {customerApp?.packagePaymentType == 2 ? (
                            <TableRow>
                              <TableCell>
                                {getTranslation(
                                  "Yearly Maintenance Charges (Incl.)",
                                  "Yearly Maintenance Charges (Incl.)",
                                  "Yearly Maintenance Charges (Incl.)"
                                )}
                              </TableCell>
                              <TableCell>
                                {customerApp?.maintenanceCharges.toFixed(2)}{" "}
                                &euro;
                              </TableCell>
                            </TableRow>
                          ) : null}
                          <TableRow>
                            <TableCell>
                              {getTranslation("VAT", "TVA", "Mehrwertsteuer") +
                                "(" +
                                customerApp?.currentVat +
                                "% " +
                                getTranslation("on", "on", "on") +
                                " " +
                                customerApp?.amountWithoutVat.toFixed(2)}{" "}
                              &euro;{")"}
                            </TableCell>
                            <TableCell>
                              {customerApp?.currentVatValue.toFixed(2)} &euro;
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getTranslation(
                                "Total Amount",
                                "Montant total",
                                "Gesamtsumme"
                              )}
                            </TableCell>
                            <TableCell>
                              {customerApp?.totalToPay.toFixed(2)} &euro;
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <br />
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'
                      >
                        <br />
                        <Typography variant='h4'>
                          {getTranslation(
                            "Billing Info:",
                            "Informations de facturation:",
                            "Rechnungsinformation:"
                          )}
                        </Typography>
                        <br />
                        {console.log("ajsdghksdkashdgasdgasd", installerModel)}
                        {installerModel.billingInfo.userType == "personal" ? (
                          <>
                            <table>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation("Name:", "Nom:", " Name:")}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  {" "}
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.firstName}{" "}
                                    {installerModel?.billingInfo.lastName}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {" "}
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Phone Number:",
                                      "Numéro de téléphone:",
                                      "Telefonnummer:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  {" "}
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.phoneNumber}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Town:",
                                      "Ville:",
                                      "Stadt, Dorf:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  {" "}
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.town}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Zipcode:",
                                      "Code postal:",
                                      " Postleitzahl:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  {" "}
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.zipCode}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Country:",
                                      "Country:",
                                      "Country:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  {" "}
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.country}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </>
                        ) : (
                          <>
                            <table>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Company:",
                                      "Société:",
                                      "Unternehmen:"
                                    )}
                                    &nbsp;
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.companyName}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Address:",
                                      "Adresse:",
                                      "Die Anschrift:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.address}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Country:",
                                      "Pays:",
                                      "Land:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.country}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Typography variant='title'>
                                    {getTranslation(
                                      "Vat: ",
                                      "T.V.A:",
                                      "Mehrwertsteuer:"
                                    )}
                                  </Typography>
                                </td>
                                <td width='50px'></td>
                                <td>
                                  <Typography variant='body2'>
                                    {installerModel?.billingInfo.countryCode}
                                    {installerModel?.billingInfo.vat}
                                  </Typography>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </table>
                          </>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ width: "100%", height: "650px" }}>
                    <CardHeader title='Payment Info' />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item sm={12} md={12} lg={12}>
                          <Box
                            width='100%'
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Typography variant='body1'>
                              {getTranslation(
                                "Pay Via",
                                "Payer via",
                                "Zahlen Sie über"
                              )}
                            </Typography>
                            <Box>
                              <img
                                src={securelogo}
                                width='150'
                                height='30'
                                alt='visa card and mastercard logo png'
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                          <Typography color='textPrimary'>
                            {getTranslation(
                              " Card Number",
                              "Numéro de carte",
                              "Kartennummer"
                            )}
                          </Typography>
                          <Box
                            mt={1}
                            border={`1px solid gray`}
                            p={1.3}
                            borderRadius='10px'
                          >
                            <CardNumberElement />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                          <Typography color='textPrimary'>
                            {getTranslation(
                              "Expiry Date",
                              "Date d'expiration",
                              "Verfallsdatum"
                            )}
                          </Typography>

                          <Box
                            border={`1px solid gray`}
                            p={1}
                            mt={1}
                            borderRadius='10px'
                          >
                            <CardExpiryElement />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                          <Typography color='textPrimary'>CVC code</Typography>

                          <Box
                            mb={2}
                            border={`1px solid gray`}
                            mt={1}
                            p={1}
                            borderRadius='10px'
                          >
                            <CardCVCElement />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box
                        display='flex'
                        alignItems='flex-end'
                        justifyContent='flex-end'
                      >
                        <LoadingButton
                          color='primary'
                          label={
                            "Pay " + customerApp?.totalToPay.toFixed(2) + " €"
                          }
                          loading={isSubmitting}
                          type='submit'
                          variant='contained'
                          size='small'
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pt={1}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
              >
                <ButtonWithLoading
                  label={getTranslation("Back", "Zurück", "Zurück")}
                  size='small'
                  variant='outlined'
                  color='primary'
                  style={{
                    marginRight: "89%",
                  }}
                  onClick={onPreviousStep}
                />
              </Box>
            </CardActions>
          </form>
        </>
      ) : (
        <Box
          pr={1}
          pt={1}
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          height={window.innerHeight}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
};

export default injectStripe(CheckoutForm);
