import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { Get, GetAllPackags, PackageEdit } from "../../actions";
import { useHistory, useParams } from "react-router-dom";
import { WEB_URL } from "../../constants";
import { useSnackbar } from "notistack";
import FileCopy from "@material-ui/icons/FileCopy";
import { IconButton } from "@material-ui/core";
import { getTranslation } from "../../heplers/translationHelper";
import { DeleteButton } from "../../components/ButttonsWithIcons";
import { Get_DeletePackage_URL } from "../../constants/apiUrls";
import { PACKAGES_DATA } from "../../actions/types";

const columns = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Title", "Titre", "Titel"),
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Description", "Description", "Beschreibung"),
  },
  {
    id: "monthlyPrice",
    numeric: false,
    disablePadding: true,
    label: getTranslation("MonthlyPrice", "Prix mensuel", "Monatlicher Preis"),
  },
  {
    id: "yearlyPrice",
    numeric: false,
    disablePadding: true,
    label: getTranslation("YearlyPrice", "Prix annuel", "Jährlicher Preis"),
  },
  {
    id: "lifeTimePrice",
    numeric: false,
    disablePadding: true,
    label: getTranslation("LifeTimePrice", "Prix de vie", "Lebenslanger Preis"),
  },

  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Actions", "Actions", "Aktionen"),
    align: "center",
  },
];

const PackagesTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [deleteRow, setDeleteRow] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const packagesData = useSelector((state) => state.packages.package);
  const { appId } = useParams();

  useEffect(() => {
    dispatch(
      GetAllPackags(
        {
          searchString,
          pageNumber: page,
          pageSize: rowsPerPage,
          appId: appId,
        },
        history
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchString]);

  const handlePackageEdit = (data) => {
    dispatch(PackageEdit(data));
    props.open(true);
  };

  const handlePackageAdd = () => {
    props.open(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const makePackageUrl = (row) => {
    const url = `${WEB_URL}market/${appId}/${row.id}`;
    return url;
  };

  const handleDelete=(row)=>{
    setDeleteRow(row)
    Get(
      {packageId:row.id},
      Get_DeletePackage_URL,
      null,
      resp=>{
        enqueueSnackbar("Package Deleted.",{variant:'success'})
        setDeleteRow(null)
        dispatch( GetAllPackags(
          {
            searchString,
            pageNumber: page,
            pageSize: rowsPerPage,
            appId: appId,
          },
          history
        ))

      },
      error=>{
        enqueueSnackbar("System was not able to delete this package.",{variant:'error'})
        setDeleteRow(null)

      })
  }
  return (
    <div>
      <Table
        title="Packages"
        colums={columns}
        page={page}
        dense
        count={packagesData?.totalCount}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        rows={packagesData?.data}
        viewSearch
        onSearch={(search) => setSearchString(search)}
        viewEdit
        onEdit={(row) => handlePackageEdit(row)}
        viewCreate
        onCreate={() => handlePackageAdd()}
        actions={[
          {
            component: (row) => (
              <IconButton
                onClick={() => {
                  const textToCopy = makePackageUrl(row);
                  // navigator clipboard api needs a secure context (https)
                  if (navigator.clipboard && window.isSecureContext) {
                    // navigator clipboard api method'
                    enqueueSnackbar("Copied", { variant: "success" });
                    return navigator.clipboard.writeText(textToCopy);
                  } else {
                    // text area method
                    let textArea = document.createElement("textarea");
                    textArea.value = textToCopy;
                    // make the textarea out of viewport
                    textArea.style.position = "fixed";
                    textArea.style.left = "-999999px";
                    textArea.style.top = "-999999px";
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    return new Promise((res, rej) => {
                      // here the magic happens
                      document.execCommand("copy") ? res() : rej();
                      enqueueSnackbar("Copied", { variant: "success" });
                      textArea.remove();
                    });
                  }
                }}
              >
                <FileCopy />
              </IconButton>
            ),
          },
          {
            component: (row) => (
            <DeleteButton onClick={()=>handleDelete(row)} loading={deleteRow?.id==row?.id}/>
            ),
          },
        ]}
        viewCopy
        onCopyClick={(row) => {
          navigator.clipboard.writeText(makePackageUrl(row));
          enqueueSnackbar("Copied", { variant: "success" });
        }}
      />
    </div>
  );
};

export default PackagesTable;
