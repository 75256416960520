import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
  },
  paper: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(4, 8),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    fontSize: "80px",
  },
  form: {
    marginTop: theme.spacing(1.5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));
