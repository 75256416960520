import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PaymentMethod from "./PaymentCard";
import BillingDetails from "./BillingInfo";
import { getPackageById } from "../../../actions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import process from "process";
import { STRIPE_API_KEY } from "../../../constants";
import { Elements, StripeProvider } from "react-stripe-elements";
import { getTranslation } from "../../../heplers/translationHelper";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const PaymentSummary = () => {
  const location = useLocation();
  console.log("kjsgbfgs", process);
  const params = useParams();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [installerModel, setInstallerModel] = useState({
    paymentToken: "",
    amount: 0,

    packageId: params.packageId,
    CustomerRegisteredAppId: params.appId,
    billingInfoId: 0,
    billingInfo: {},
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const base64Data = params.get("data");

    if (base64Data) {
      const decodedData = atob(base64Data); // Decode Base64
      const parsedObject = JSON.parse(decodedData);
      console.log("decdoed", parsedObject);
      
    }
  }, [location.search]);

  const dispatch = useDispatch();

  function getSteps() {
    return [
      getTranslation("Billing Info", "Billing Info", "Billing Info"),
      getTranslation("Payment", "Payment", "Payment"),
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <BillingDetails
              onBillingInfoSelected={(billingInfo) => {
                // setBillingInfo(billingInfo)
                setInstallerModel({
                  ...installerModel,
                  billingInfoId: billingInfo?.id,
                  billingInfo: billingInfo,
                });
                handleNext();
              }}
            />
          </>
        );

      case 1:
        return (
          <StripeProvider apiKey={STRIPE_API_KEY}>
            <Elements>
              <PaymentMethod
                installerModel={installerModel}
                onNextStep={() => handleNext()}
                onPreviousStep={() => handleBack()}
              />
            </Elements>
          </StripeProvider>
        );
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {getTranslation(
                "All steps completed - you",
                "Toutes les étapes terminées - vous",
                "Alle Schritte abgeschlossen - Sie"
              )}
              &apos;{getTranslation("re finished", "re fini", "wieder fertig")}
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              {getTranslation("Reset", "Réinitialiser", "Zurücksetzen")}
            </Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PaymentSummary;
