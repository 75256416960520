import {combineReducers} from "redux";
import auth from "./userReducer"
import app from "./appReducer"
import customers from "./customerReducer"
import packages  from "./packagesReducer"
import settings from "./settingReducer"
export default combineReducers({
  auth,
  app,
  customers,
  packages,
  settings,
});