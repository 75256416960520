import React, { useEffect, useState } from "react";
import TableComponent from "../../components/table";
import { getTranslation } from "../../heplers/translationHelper";
import CardComponent from "../../components/Card";
import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import {
  Get_AllUsersActivitiesWithPagination_URL,
  Get_AllUsersLogsWithPagination_URL,
  Get_AppsList_URL,
} from "../../constants/apiUrls";
import { Get } from "../../actions";
import DialogComponent from "../../components/Dialog";
import CustomerSummaryDialog from "./SummaryDialog";
import { format } from "../../heplers/format";
import moment from "moment";
import { useSnackbar } from "notistack";

export const customerStyle = makeStyles((theme) => ({
  searchBar: {
    borderRadius: "20px",
    marginBottom: "20px",
  },
  daysBar: {
    borderRadius: "20px",
    marginLeft: "10px",
  },
}));

const columns = [
  {
    id: "visitorID",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Visitor ID", "Visitor ID", "Visitor ID"),
    custom: true,
  },
  {
    id: "dateTime",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Date/Time", "Date/Time", "Date/Time"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>{format("dateTime", row?.dateTime)}</Typography>
        </div>
      );
    },
  },
  {
    id: "hasRegistered",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Register", "Register", "Register"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>{row?.hasRegistered ? "true" : "false"}</Typography>
        </div>
      );
    },
  },
  {
    id: "hasBought",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Bought", "Bought", "Bought"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>{row?.hasBought ? "true" : "false"}</Typography>
        </div>
      );
    },
  },

  {
    id: "actions",
    numeric: true,
    label: getTranslation("Summary", "Summary", "Summary"),
    disablePadding: false,
    align: "right",
    // label: "Actions",
  },
];

const CustomersTracking = () => {
  const classes = customerStyle();
  const [searchString, setSearchString] = useState("");
  const [apps, setApps] = useState([]);
  const [visitorId, setVisitorId] = useState();
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [customerLogs, setCustomerLogs] = useState([]);
  const [fromdate, setFromdate] = useState(new Date());
  const [todate, setTodate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  let pageSize = localStorage.getItem("sizeCustomerLogs");
  if (pageSize == null) {
    localStorage.setItem("sizeCustomerLogs", 10);
    pageSize = 10;
  }
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  useEffect(() => {
    Get(
      {},
      Get_AppsList_URL,
      null,
      (resp) => {
        setApps(resp.data);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    getCustomerLogs();
  }, [page, rowsPerPage]);

  const getCustomerLogs = () => {
    setIsLoading(true);
    Get(
      {
        searchString: searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
        sort: 0,
        fromDate: fromdate,
        toDate: todate,
      },
      Get_AllUsersActivitiesWithPagination_URL,
      null,
      (resp) => {
        setCustomerLogs(resp.data);
        setIsLoading(false);
      },
      (error) => {
        enqueueSnackbar(error?.data ? error?.data : "internal server error", {
          variant: "error",
        });
        setIsLoading(true);
      }
    );
  };


  const selectedVisitor = (visitor) => {
    setVisitorId(visitor.visitorID);
    setSummaryDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeCustomerLogs", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeCustomerLogs"));
    setPage(0);
  };

  return (
    <div>
      <CardComponent
        title={getTranslation(
          "Customers Details",
          "Customers Details",
          "Customers Details"
        )}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xm={12} md={3} xl={3}>
              <SearchBar
                onCancelSearch={() => {
                  setSearchString("");
                }}
                onChange={(searchString) => {
                  setSearchString(searchString);
                }}
                onRequestSearch={() => {
                  getCustomerLogs();
                }}
                searchIcon={
                  <SearchIcon
                    style={{ fontSize: "32px", marginRight: "20px" }}
                  />
                }
                className={classes.searchBar}
              />
            </Grid>
            <Grid item xm={12} md={3} xl={3} style={{ marginTop: "4px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "9px", marginRight: "5px" }}>
                  From
                </label>
                <TextField
                  fullWidth
                  id="fromDate"
                  type="date"
                  value={moment(fromdate).format("YYYY-MM-DD")}
                  title={getTranslation(" From Date", "From Date", "From Date")}
                  required
                  size="small"
                  variant="outlined"
                  name="fromDate"
                  onChange={(e, date) => {
                    setFromdate(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xm={12} md={3} xl={3} style={{ marginTop: "4px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "9px", marginRight: "5px" }}>
                  To
                </label>
                <TextField
                  fullWidth
                  id="toDate"
                  type="date"
                  value={moment(todate).format("YYYY-MM-DD")}
                  title={getTranslation(" To Date", "To Date", "To Date")}
                  required
                  size="small"
                  variant="outlined"
                  name="toDate"
                  onChange={(e, date) => {
                    setTodate(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xm={12} md={3} xl={3} style={{ marginTop: "4px" }}>
              <ButtonWithLoading
                label={getTranslation("Filter", "Filter", "Filter")}
                size="medium"
                variant="contained"
                color="primary"
                margin="20px"
                onClick={() => {
                  getCustomerLogs();
                }}
              />
            </Grid>

            {/* <Grid item xm={12} md={12} xl={4} style={{ marginTop: "4px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "9px" }}>From</label>
                <TextField
                  name="days"
                  variant="outlined"
                  size="small"
                  value={days}
                  type="number"
                  onChange={(e) => {
                    setDays(e.target.value);
                  }}
                  inputProps={{ min: 0 }}
                  className={classes.daysBar}
                />
                <ButtonWithLoading
                  style={{
                    marginLeft: "10px",
                  }}
                  label={getTranslation("Filter", "Filter", "Filter")}
                  // loading={isSubmitting}
                  // onClick={() => handleClearSMS()}
                  color="primary"
                  type="submit"
                  variant="contained"
                />
              </Box>
            </Grid>
            <Grid item xm={12} md={12} xl={4} style={{ marginTop: "4px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <label style={{ marginTop: "9px" }}>To</label>
                <TextField
                  name="days"
                  variant="outlined"
                  size="small"
                  value={days}
                  type="number"
                  onChange={(e) => {
                    setDays(e.target.value);
                  }}
                  inputProps={{ min: 0 }}
                  className={classes.daysBar}
                />
                <ButtonWithLoading
                  style={{
                    marginLeft: "10px",
                  }}
                  label={getTranslation("Filter", "Filter", "Filter")}
                  // loading={isSubmitting}
                  // onClick={() => handleClearSMS()}
                  color="primary"
                  type="submit"
                  variant="contained"
                />
              </Box>
            </Grid> */}
          </Grid>

          <TableComponent
            dense
            loading={isLoading}
            colums={columns}
            count={customerLogs?.totalCount}
            rows={customerLogs?.data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) =>
              handleChangePage(event, newPage)
            }
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            actions={[
              {
                component: (rowData) => (
                  <ButtonWithLoading
                    label={getTranslation("Summary", "Summary", "Summary")}
                    size="small"
                    variant="contained"
                    color="primary"
                    margin="20px"
                    onClick={() => {
                      selectedVisitor(rowData);
                    }}
                  />
                ),
              },
            ]}
          />
        </CardContent>
      </CardComponent>
      <DialogComponent
        open={summaryDialogOpen}
        onClose={() => setSummaryDialogOpen(false)}
        title={getTranslation("Summary", "Summary", "Summary")}
        fullWidth
        maxWidth="md"
      >
        <CustomerSummaryDialog visitorId={visitorId} />
      </DialogComponent>
    </div>
  );
};

export default CustomersTracking;
