import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import axios from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import { Get } from "../../../actions";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Get_ValidateEuropeVAT_URL } from "../../../constants/apiUrls";
import { countriesWithCode, CountrySelection } from "../../../constants/countries";
import { getTranslation } from "../../../heplers/translationHelper";

function TextMaskCustom(props) {
  const { inputRef,country, ...other } = props;
  var european=EuropeanCountries[country];
  
  // console.log("ajksdhkasjdhjkladjkas",european)
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
    />
  );
 
}

export const EuropeanCountries={
  "Austria":{
    vat:20,
    key:"AT"
  },
  "Belgium":{
    vat:21,
    key:"BE"

  },
  "Bulgaria":{
    vat:20,
    key:"BG"
  },
  // "Croatia":{
  //   vat:21,
  //   key:"HR"
  // },
  "Cyprus":{
    vat:19,
    key:"CY"
  },
  "Czech Republic":{
    vat:21,
    key:"CZ"
  },
  "Denmark":{
    vat:25,
    key:"DK"
  },
  "Estonia":{
    vat:20,
    key:"EE"
  },
  "Finland":{
    vat:24,
    key:"FI"
  },
  "France":{
    vat:20,
    key:"FR"
  },
  "Germany":{
    vat:19,
    key:"DE"
  },
  "Greece":{
    vat:24,
    key:"GR"
  },
  "Hungary":{
    vat:27,
    key:"HU"
  },
  "Ireland":{
    vat:23,
    key:"IE"
  },
  "Italy":{
    vat:22,
    key:"IT"
  },
  "Latvia":{
    vat:21,
    key:"LV"
  },
  "Lithuania":{
    vat:21,
    key:"LT"
  },
  "Luxembourg":{
    vat:17,
    key:"LU"
  },
  "Malta":{
    vat:18,
    key:"MT"
  },
  "Netherlands":{
    vat:21,
    key:"NL"
  },
  "Poland":{
    vat:23,
    key:"PL"
  },
  "Portugal":{
    vat:23,
    key:"PT"
  },
  "Romania":{
    vat:19,
    key:"RO"
  },
  "Slovakia":{
    vat:20,
    key:"SK"
  },
  "Slovenia":{
    vat:22,
    key:"SI"
  },
  "Spain":{
    vat:21,
    key:"ES"
  },
  "Sweden":{
    vat:25,
    key:"SE"
  },


}

export const CreateBillingInfoDialog = ({
  editBillingInfo,
  onSubmit,
  isOpen,
  onClose,
}) => {
  if (editBillingInfo)
    if (editBillingInfo?.vat == '') {
      editBillingInfo.userType = 'personal'
      
    } else {
      editBillingInfo.userType = 'company'
      var europe = EuropeanCountries[editBillingInfo.country]
      if(europe){
        editBillingInfo.countryCode=europe.key
      }
    }
  const initialValues = {
    firstName: "",
    lastName: "",
    companyName: "",
    town: "",
    zipCode: "",
    // title: "",
    country: "",
    countryCode: "",
    address: "",
    phoneNumber: "",
    vat: "",
    isVATVerified: false,
    appUserId: 1,
    userType:'personal'
  };
  const basicValidationSchema = Yup.object().shape({
    firstName: Yup.string()
    .when("userType", {
      is: "personal",
      then: Yup.string().required("Field is required")
    }),
    lastName: Yup.string()
    .when("userType", {
      is: "personal",
      then: Yup.string().required("Field is required")
    }),
    town: Yup.string().when("userType", {
      is: "personal",
      then: Yup.string().required("Field is required")
    }),
    zipCode: Yup.string().when("userType", {
      is: "personal",
      then: Yup.string().required("Field is required")
    }),
    country: Yup.string().required("Country is required"),
    phoneNumber: Yup.string().when("userType", {
      is: "personal",
      then: Yup.string().required("Field is required")
    }),
    appUserId: Yup.number().min(1),
  });
  const defaultValue = editBillingInfo ? editBillingInfo : initialValues;
  const {enqueueSnackbar} =useSnackbar();

  const verifyVAT = (countryCode, VAT,setFieldError,setSubmitting,setFieldValue) => {
    setSubmitting(true);
    Get(
      { countryCode: countryCode, vatNumber: VAT },
      Get_ValidateEuropeVAT_URL,
      null,
      resp => {
        resp = resp.data;
        if (resp.isValid) {
         setFieldValue("isVATVerified",resp.isValid)
         setFieldValue("companyName",resp.name)
         setFieldValue("address",resp.address)


        } else {
          setFieldError("vat", "Invalid VAT please verify your information again")
        }
        setSubmitting(false);
      },
      error => {
        setSubmitting(false);
      }
    )
  }

  return (

    <Formik
      enableReinitialize
      initialValues={defaultValue}
      validationSchema={basicValidationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        values.countryCode=countriesWithCode.find(x=>x.name==values.country).code
        onSubmit(values, actions);


      }}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        setFieldError,
        setFieldTouched,
        setErrors
      }) => (
        <Form>
          <Grid container spacing={2}>
                        
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
             mb={5}
             display="flex"
             alignItems="center"
             justifyContent="center"
              >
                <ToggleButtonGroup
                  size="small"
                  id="transactionStatus"
                  value={values.userType}
                  exclusive
                  onChange={(event, status) => {
                    if (status !== null) {
                      setFieldValue("userType", status);
                      setFieldValue("vat","")
                    }
                  }}
                >
                  <ToggleButton
                    disabled={editBillingInfo?.isVATVerified == true}
                    value="personal" aria-label="left aligned">
                    <Typography>
                      {getTranslation("Personal", "Personal", "Personal")}
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    disabled={editBillingInfo?.isVATVerified}
                    value="company" aria-label="left aligned">
                    <Typography>
                      {getTranslation("Subject To VAT", "Subject To VAT", "Subject To VAT")}
                    </Typography>
                  </ToggleButton>

                </ToggleButtonGroup>
            </Box>
            </Grid>
            
            {values.userType=='personal' ?<>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                id="firstName"
                label={getTranslation("First Name", "Prénom", "Vorname")}
                required
                size="small"
                variant="outlined"
                name="firstName"
                {...getFieldProps("firstName")}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                id="lastName"
                label={getTranslation("Last Name", "Nom", "Nachname")}
                required
                size="small"
                variant="outlined"
                name="lastName"
                {...getFieldProps("lastName")}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                id="phoneNumber"
                label={getTranslation(
                  "Phone Number",
                  "Numéro de téléphone",
                  "Telefonnummer"
                )}
                required
                size="small"
                variant="outlined"
                name="phoneNumber"
                {...getFieldProps("phoneNumber")}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                id="town"
                label={getTranslation("Town", "Ville", "Stadt")}
                required
                size="small"
                variant="outlined"
                name="town"
                {...getFieldProps("town")}
                error={touched.town && Boolean(errors.town)}
                helperText={touched.town && errors.town}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                id="zipCode"
                label={getTranslation(
                  "Zip Code",
                  "Code postal",
                  "Postleitzahl"
                )}
                required
                size="small"
                variant="outlined"
                name="zipCode"
                {...getFieldProps("zipCode")}
                error={touched.zipCode && Boolean(errors.zipCode)}
                helperText={touched.zipCode && errors.zipCode}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CountrySelection
                    id="country"
                    label={getTranslation("Country", "Pays", "Land")}
                    selected={values.country}
                    required
                    onChange={(e, country) => {
                      setFieldValue("country", country);
                    }}
                    error={touched.country && Boolean(errors.country)}
                  />
            </Grid>
            </>
            :<>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CountrySelection
                    disabled={editBillingInfo?.isVATVerified == true}
                    id="country"
                    label={getTranslation("Country", "Pays", "Land")}
                    selected={values.country}
                    required
                    onChange={(e, country) => {
                      var europe = EuropeanCountries[country]
                      if (europe) {
                        setFieldValue("country", country);
                        setFieldValue("countryCode", europe.key);
                      }

                      setFieldValue("isVATVerified", false)
                    }}
                    error={touched.country && Boolean(errors.country)}
                    filterCountries={(countries) => {
                      return countries.filter(country => EuropeanCountries[country] ? true : false)
                    }}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                    disabled={editBillingInfo?.isVATVerified == true}

                fullWidth
                id="vat"
                label={getTranslation(
                  "Tax ID (number only)",
                  "Numéro d'identification fiscale (number only)",
                  "Steuer ID (number only)"
                )}
                // required
                onKeyPress={(e)=>{
                  const isNumber = /^[0-9]$/i.test(e.key)
                  if(!isNumber && e.key!='BackSpace')
                    e.preventDefault();
                }}
                onPaste={(e)=>{
                  e.preventDefault();
                  var pastedText = '';

                  if (e.clipboardData && e.clipboardData.getData)
                  {// Standards Compliant FIRST!
                   pastedText = e.clipboardData.getData('text/plain');
                  }
                  else if (window.clipboardData && window.clipboardData.getData)
                  {// IE
                   pastedText = window.clipboardData.getData('Text');
                  }

                  const thenum = pastedText.replace(/[^0-9]/g, '');
                  setFieldValue("vat",thenum)
                }}
                size="small"
                variant="outlined"
                name="vat"
                value={values.vat}
                onChange={(e)=>{
                  setFieldValue("vat",e.target.value)
                  setFieldValue("isVATVerified",false)

                }}
                error={touched.vat && Boolean(errors.vat)}
                helperText={touched.vat && errors.vat}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            { typeof editBillingInfo?.isVATVerified =="undefined" || editBillingInfo?.isVATVerified ==false ? 
            
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <ButtonWithLoading
                    style={{ marginLeft: '50px' }}
                    
                    label={getTranslation("Verify", "Verify", "Verify")}
                    size="small"
                    variant="outlined"
                    color="primary"
                    loading={isSubmitting}
                    onClick={()=>{
                      var europe = EuropeanCountries[values.country]
                      if(europe){
                      setFieldTouched("vat",true,true)
                      verifyVAT(europe.key,values.vat,setFieldError,setSubmitting,setFieldValue)
                    }else{
                      enqueueSnackbar(getTranslation("Selected country is not a member of European community.","Selected country is not a member of European community.","Selected country is not a member of European community."),{variant:"warning"})
                    }
                    }}
                  />
            </Grid>
            :null}
            {values.isVATVerified ? <>
              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                disabled
                id="companyName"
                label={getTranslation(
                  "Company Name",
                  "Nom de l'entreprise",
                  "Firmenname"
                )}
                required
                size="small"
                variant="outlined"
                name="companyName"
                {...getFieldProps("companyName")}
                error={touched.companyName && Boolean(errors.companyName)}
                helperText={touched.companyName && errors.companyName}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                disabled

                id="address"
                label={getTranslation("Address", "Adresse", "Adresse")}
                required
                size="small"
                variant="outlined"
                name="address"
                {...getFieldProps("address")}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
                inputProps={{
                  shrink: true,
                }}
              />
            </Grid>
            </>:null}
            </>}
           
          </Grid>
          {values.isVATVerified || values.userType=='personal' ?
            <Box
              mt={5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ButtonWithLoading
                style={{ marginLeft: '50px' }}
                disabled={Object.keys(errors).length > 0}
                label={getTranslation("Next", "Suivant", "Weiter")}
                size="small"
                variant="outlined"
                color="primary"
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            </Box>
            : null}
          <br />
        </Form>
      )}
    </Formik>
  );
};
