import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, CircularProgress } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import styles from "../../../assests/styles/views/customerView/market/viewPackage";
import { DialogContent } from "@material-ui/core";
import { Get } from "../../../actions";
import { GetPackageEndPoint } from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";

const ViewAppPackages = ({ packageId }) => {
  const classes = styles();
  const [data, setData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    Get(
      { packageId: packageId },
      GetPackageEndPoint,
      null,
      (resp) => {
        setData(resp?.data);
        setDataLoading(false);
      },
      (error) => {
        setDataLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    Get(
      { packageId: packageId },
      GetPackageEndPoint,
      null,
      (resp) => {
        setData(resp?.data);
        setDataLoading(false);
      },
      (error) => {
        setDataLoading(false);
      }
    );
  }, []);
  return dataLoading ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress />
      <Typography>
        {getTranslation(
          " We are verifying your email. Please wait ",
          "Nous vérifions votre e-mail. S'il vous plaît, attendez",
          "Wir überprüfen Ihre E-Mail. Warten Sie mal"
        )}
      </Typography>
    </Grid>
  ) : dataLoading ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress />
      <Typography>
        {getTranslation(
          "We are verifying your email. Please wait",
          "Nous vérifions votre e-mail. S'il vous plaît, attendez",
          "Wir überprüfen Ihre E-Mail. Warten Sie mal"
        )}{" "}
      </Typography>
    </Grid>
  ) : (
    dataLoading?  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: "100vh" }}
  >
    <CircularProgress />
    <Typography>We are verifying your email. Please wait </Typography>
  </Grid>:
    <DialogContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">
          {getTranslation("Package Name:", "Nom du paquet:", "Paketnamen:")}
        </Typography>
        <Typography variant="body1">{data.title}</Typography>
      </Box>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1">
          {" "}
          {getTranslation(
            "Package Price:",
            "Prix ​​du coffret:",
            "Paketpreis:"
          )}
        </Typography>
        <Typography variant="body1">{data?.price}</Typography>
      </Box>
      <Box mt={3} display="flex" flexDirection="column">
        <Typography variant="h6">
          {getTranslation("Features", "Caractéristiques", "Merkmale")}
        </Typography>
        {ReactHtmlParser(data.features)}
      </Box>
    </DialogContent>
  );
};

export default ViewAppPackages;
