import PropTypes from "prop-types";
import axios from "../heplers/apiHelper";
import axiosRefresh from "axios";

// import { Get_DeleteFile_URL, Get_FileInfo_URL, Get_File_URL } from '../constants/apiUrls'
import { URL } from "../constants";
import authService from "../utils/authUtils";
import { Get_LanguageDictionary_URL } from "../constants/apiUrls";
let isAlreadyFetchingAccessToken = false
let getsubscribers = []
let postsubscribers = []

// export async function validateAuthentication(type, callback) {
//   if (isAlreadyFetchingAccessToken) {

//     type = "get" ? getsubscribers.push(callback) : postsubscribers.push(callback)
//   }
//   else if (authService?.isAuthenticated()) {

//     await callback();
//   } 
//   else if (authService?.isRefreshAuthenticated()) {
//     isAlreadyFetchingAccessToken = true;
//     const resp = await authService.tryRefresh(axiosRefresh);

//     isAlreadyFetchingAccessToken = false;
//     if (resp) {
//       await callback()
//       // req.headers.Authorization = `Bearer ${authService.getUserToken()}`;
//       // return req;
//       type = "get" ? getsubscribers.forEach(sub => {
//         sub()
//       }) :
//         postsubscribers.forEach(sub => {
//           sub();
//         })
//     } 
//     else {
//       await callback();
//     }
//   } else {
//     await callback();
//   }
// }


export async function validateAuthentication(type, callback) {
  if (isAlreadyFetchingAccessToken) {
    type === "get"
      ? getsubscribers.push(callback)
      : postsubscribers.push(callback);
  } else if (authService && authService.isAuthenticated()) {
    await callback(authService.getUserToken());
  } else if (authService && authService.isRefreshAuthenticated()) {
    isAlreadyFetchingAccessToken = true;
    const resp = await authService.tryRefresh(axiosRefresh);

    isAlreadyFetchingAccessToken = false;
    if (resp) {
      await callback(resp.accessToken);
      // req.headers.Authorization = `Bearer ${authService.getUserToken()}`;
      // return req;
      type === "get"
        ? getsubscribers.forEach((sub) => {
            sub(resp.accessToken);
          })
        : postsubscribers.forEach((sub) => {
            sub(resp.accessToken);
          });
    } else {
      //console.log("ajkshdljkashdjkadask else",callback,resp.accessToken)
      await callback(authService.getUserToken());
    }
  } else {
    await callback(authService?.getUserToken());
  }
}

export async function Get(payload, actionUrl, history, onSuccess, onError) {
  try {
    await validateAuthentication("get", async (token) => {
      try {

        const response = await axios(token).get(actionUrl, { params: payload });
        onSuccess(response)
      } catch (error) {
        if (error.response.status === 401) {
          authService.setSession(null)
          return Get(payload,actionUrl,null,onSuccess,onError)
        }
        if (onError) onError(error.response)
        
      }
    })
  } catch (error) {
    if (error?.response?.status === 401) {
      window.location.reload();
    }
  }


}

Get.propTypes = {
  payload: PropTypes.node.isRequired,
  actionUrl: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export async function Post(payload, actionUrl, history, onSuccess, onError) {
  await validateAuthentication("post", async (token) => {
    try {
      const response = await axios(token).post(actionUrl, payload);
      onSuccess(response)
    } catch (error) {
      if (error.response.status === 401) {
        authService.setSession(null)
        return Get(payload,actionUrl,null,onSuccess,onError)
      }
      onError(error.response)
    }
  })
}

Post.propTypes = {
  payload: PropTypes.node.isRequired,
  actionUrl: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};


export async function getLanguage(onSuccess,onError){
  try {

    const response = await axios(null).get(Get_LanguageDictionary_URL, { params: {} });
    onSuccess(response)
  } catch (error) {
    if (onError) onError(error.response)
    if (error.response.status === 401) {
      window.location.reload();
    }
  }
}