/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { IoMdApps } from "react-icons/io";
import LanguageIcon from "@material-ui/icons/Language";
import { AiOutlineUsergroupDelete } from "react-icons/ai";
import SettingsIcon from "@material-ui/icons/Settings";
import { FaChartArea } from "react-icons/fa";
import PropTypes from "prop-types";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import useStyles from "../../assests/styles/layout/SideBar";
import { useSelector } from "react-redux";
import PersonIcon from "@material-ui/icons/Person";
import { Link as RouterLink } from "react-router-dom";
import { AppManagerLogo, ImpactSoftLogo } from "../../icons";
import Copyright from "../../utils/copyRightUtils";
import { getTranslation } from "../../heplers/translationHelper";
import { AddToQueue, SmsSharp } from "@material-ui/icons";

const superAdminNavConfig = [
  {
    subheader: "",
    items: [
      {
        href: "/apps",
        icon: IoMdApps,
        title: getTranslation("Applications", "Applications", "Anwendungen"),
      },

      {
        href: "/customers",
        icon: AiOutlineUsergroupDelete,
        title: getTranslation("Customers", "Les clients", "Kunden"),
      },
      // {
      //   href: "/settings",
      //   icon: SettingsIcon,
      //   title: getTranslation(
      //     "Email Settings",
      //     "Paramètres de messagerie",
      //     "Email Einstellungen"
      //   ),
      // },
      {
        href: "/profile",
        icon: PersonIcon,
        title: getTranslation("Profile", "Profil", "Profil"),
      },
      {
        href: "/languages",
        icon: LanguageIcon,
        title: getTranslation("Language", "Langue", "Sprache"),
      },
      {
        href: "/manageOrganizations",
        icon: AddToQueue,
        title: getTranslation("Manage Organizations", "Manage Organizations", "Manage Organizations"),
      },
      {
        href: "/pendingSMS",
        icon: SmsSharp,
        title: getTranslation("Pending SMS", "Pending SMS", "Pending SMS"),
      },
      {
        href: "/customertracking",
        icon: SmsSharp,
        title: getTranslation("Customers Tracking", "Customers Tracking", "Customers Tracking"),
      },
    ],
  },
];

const CustomerNavConfig = [
  {
    subheader: "",
    items: [
      {
        href: "/myapps",
        icon: IoMdApps,
        title: getTranslation(
          "My Apps",
          "Mes applications",
          "Meine Applikationen"
        ),
      },

      {
        href: "/market",
        icon: FaChartArea,
        title: getTranslation("Market", "Marché", "Markt"),
      },
      {
        href: "/profile",
        icon: PersonIcon,
        title: getTranslation("Profile", "asadada", "Profil"),
      },
    ],
  },
];

function renderNavItems({ items, depth, pathname, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, pathname, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 0,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        open={true}
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

function Sidebar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const [navConfig, setNavConfig] = React.useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      console.log("ajklsdhaskljdhasjkdhaskjd", user);
      if (user.roles === "SuperAdmin") {
        setNavConfig(superAdminNavConfig);
      } else {
        setNavConfig(CustomerNavConfig);
      }
    }
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
    >
      <Box p={2}>
        <RouterLink to="/">
          <ImpactSoftLogo />
        </RouterLink>
        {navConfig.map((config) => (
          <List
            key={config.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {config.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: config.items,
              pathname: location.pathname,
            })}
          </List>
        ))}
      </Box>
      <Box flexGrow={1} />
      <div
        style={{
          margin: "auto",
          marginBottom: "15px",
          color: "#ffffff",
        }}
      >
        <AppManagerLogo />
        <Copyright />
      </div>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default Sidebar;
