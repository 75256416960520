import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom";
import { getUserData } from "../actions";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Grid
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import authService from '../utils/authUtils';
import { SET_USER } from '../actions/types';
const Auth = (props) => {
  /*const Auth = ({setAuth,userLoggedOut,onGetToken,onRefresh,children}) => {
  
    useEffect(()=>{
      setCookie("app-auth",setAuth)
    },[setAuth])
  
    
    useEffect(()=>{
      removeCookie("app-auth")
    },[userLoggedOut])
    const [cookies, setCookie, removeCookie] = useCookies(['app-auth']);
    */
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const location = useLocation();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();
 
  if(window.location.search.includes("visitorId")){
    localStorage.setItem("visitorId", window.location.search.split("=")[1]);
  }
  const initAuth = async () => {
    if (authService.isAuthenticated() || authService.isRefreshAuthenticated()) {
  dispatch(getUserData(
        resp => {
          authService.removeRedirectURL();
          dispatch({ type: SET_USER, payload: resp.data })
          setLoading(false);
        },
        error => {
          enqueueSnackbar("Unknown Error", { variant: "error" })
          authService.setRedirectURL(location.pathname)
          if (location.pathname.includes("market"))
            history.push("/register")
          else
            history.push("/login")
          setLoading(false);

        }
      ))
    } else {
      authService.setRedirectURL(location.pathname)

      if (location.pathname.includes("market"))
        history.push("/register")
      else
        history.push("/login")
      setLoading(false);
    }



    /*   await dispatch(()=>{
         if (cookies['app-auth']) {
           const auth = cookies['app-auth'];
           if (auth.expiryDate < new Date() && auth.refreshExpiryDate > new Date())
             onRefresh(auth.refreshToken)
           else
             onGetToken()
         }
         
       }); */
  };

  useEffect(() => {
    if (window.location.href.includes("impact-soft") && window.location.protocol !== 'https:') {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
    initAuth();
  }, [])
  if (loading)
    return <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <CircularProgress />
    </Grid>
  return props.children;
}

export default Auth
