import React from "react";
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  Grid,
  makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import { withRouter, useParams } from "react-router-dom";
import LoadingButton from "../../components/ButtonWithLoading";
import { Formik } from "formik";

import { resetPassword } from "../../actions";
import { useDispatch } from "react-redux";
import checkErrors from "../../utils/responseUtils";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20vh",
  },
}));

const ChangePassword = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  return (
    <Container maxWidth="xs">
      <Box width="100%" height="100%">
        <Paper className={classes.root}>
          <Box p={2} textAlign="center">
            <Box mb={3}>
              <Typography variant="h6" color="initial">
                {getTranslation(
                  "Reset your password",
                  "réinitialisez votre mot de passe",
                  "Setze dein Passwort zurück"
                )}
              </Typography>
            </Box>
            <Box>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .min(6, "Password atleast 6 character long")
                    .required("Password is required"),
                  confirmPassword: Yup.string()
                    .min(6, "Password atleast 6 character long")
                    .required("Confirm password is required")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  dispatch(
                    resetPassword({ ...values, token: params.token })
                  ).then((res) => {
                    const errors = checkErrors(res);
                    if (!errors) {
                      props.history.push("/login");
                      enqueueSnackbar("Password Reset", {
                        variant: "success",
                      });

                      setSubmitting(false);
                    } else {
                      errors?.map((error) =>
                        enqueueSnackbar(error.message, { variant: "error" })
                      );
                      setSubmitting(false);
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="password"
                          label={getTranslation(
                            "New Password",
                            "nouveau mot de passe",
                            "Neues Kennwort"
                          )}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="password"
                          label={getTranslation(
                            "Confirm Password",
                            "Confirmer le mot de passe",
                            "Kennwort bestätigen"
                          )}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={
                            touched.confirmPassword &&
                            Boolean(errors.confirmPassword)
                          }
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                        />
                      </Grid>
                      <Grid item sm={12} md={12} lg={12}>
                        <LoadingButton
                          color="primary"
                          label={getTranslation(
                            "Reset Password",
                            "Réinitialiser le mot de passe",
                            "Kennwort zurücksetzen"
                          )}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default withRouter(ChangePassword);
