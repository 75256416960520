import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetAllApplications, ApplicationEdit, GetAppById } from "../../actions";
import ListIcon from "@material-ui/icons/List";
import { IconButton } from "@material-ui/core";
import DialogComponent from "../../components/Dialog";
import AddNewPackage from "../packages/AddNewPackage";
import { LoadImage } from "../../components/LoadImage";
import AddNewApp from "./AddNewApp";
import { getTranslation } from "../../heplers/translationHelper";

const columns = [
  {
    id: "icon",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Icon", "Ic�ne", "Symbol"),
    custom: true,
    component: ({ row }) => (
      <LoadImage imageAddress={row.icon} width="45" height="45" />
    ),
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Title", "Titre", "Titel"),
  },
  { id: "prefix", numeric: false, disablePadding: false, label: "Prefix" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Description", "Description", "Beschreibung"),
  },
  {
    id: "actions",
    numeric: true,
    width: 150,
    disablePadding: false,
    align: "right",
    label: "Actions",
  },
];

const Customers = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [open, setOpen] = useState(false);

  const { apps } = useSelector((state) => state.app);
  const editApp = useSelector((state) => state.app.edit);

  useEffect(() => {
    setRows(apps.data);
  }, [apps.data]);

  const handleEdit = (row) => {
    dispatch(ApplicationEdit(row));
    setOpen(true);
  };

  useEffect(() => {
    dispatch(
      GetAllApplications(
        {
          searchString: props.searchText,
          pageNumber: page,
          pageSize: rowsPerPage,
        },
        history
      )
    );
  }, [page, rowsPerPage, props.searchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAppPackages = (id) => {
    history.push(`/packages/${id}`);
  };

  return (
    <div>
      <Table
        dense
        title={getTranslation("Applications", "Applications", "Anwendungen")}
        colums={columns}
        rows={rows}
        count={rows?.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
        onSearch={(search) => setSearchString(search)}
        viewEdit
        onEdit={(row) => handleEdit(row)}
        actions={[
          {
            component: (app) => (
              <IconButton
                onClick={() => {
                  handleAppPackages(app.id);
                }}
              >
                <ListIcon />
              </IconButton>
            ),
          },
        ]}
      />
      <DialogComponent
        title={editApp ? "Edit application" : "Add new application"}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          setOpen(false);

          dispatch(ApplicationEdit(null));
        }}
      >
        <AddNewApp onClose={(value) => setOpen(value)} />
      </DialogComponent>
    </div>
  );
};

export default Customers;
