import { LOADING_APPS, APPS_DATA, APPS_ERRORS ,APPS_EDIT,APP_DATA} from "../actions/types";

const initState = {
  loading:false,
  errors:null,
  apps:[],
  edit:null,
  app:null
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_APPS:
      return {
        ...state,
        loading: true,
      };

    case APPS_DATA:
      return {
        ...state,
        loading: false,
        errors: null,
        apps: action.payload,
      };
    case APPS_ERRORS:
      return {
        ...state,
        apps: [],
        loading: false,
        errors: action.payload,
      };
    case APPS_EDIT:
      return {
        ...state,
        edit: action.payload,
      };
      case APP_DATA:
        return{
          ...state,
          errors:null,
          app:action.payload
        }
    default:
      return { ...state };
  }
};

export default userReducer;
