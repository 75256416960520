import React from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  CardActions,
  Button,
  Box,
} from "@material-ui/core";
import {withRouter} from "react-router-dom"
import { GetImage } from "../../../actions";
import { getTranslation } from "../../../heplers/translationHelper";


const styles = makeStyles((theme) => ({
  cardRoot: {
    borderRadius: "10px",
  },
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    flexWrap: "wrap",
  },
  sizeAvatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    objectFit: "contain",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: "1px",
  },
  py: {
    padding: "10px 0px",
  },
  button: {
    textAlign: "center",
  },
}));

const MarketAppCard = (props) => {
  const classes = styles();

  const handleViewPackages=(id)=>{
    props.history.push(`/market/${id}`)
  }
  return (
    <Card elevation={2} classes={{ root: classes.cardRoot }}>
      <CardContent>
        <div className={classes.root}>
          <img
            className={classes.sizeAvatar}
            alt="logo"
            src={GetImage(props.data.icon)}
          />
          <Typography className={classes.py} variant="h6">
            {props.data.title}
          </Typography>
         <Box height="100px">
            <Typography
            className={classes.py}
            variant="body1"
          >
            {props.data.description}
          </Typography>
         </Box>
        </div>
      </CardContent>
      <CardActions>
        <Box p={1} width="100%" display="flex" justifyContent="center">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="small"
            onClick={(e) => handleViewPackages(props.data.id)}
          >
            {getTranslation("View Packages","View Packages","View Packages")}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

export default withRouter(MarketAppCard);
