import React from "react";
import { Grid } from "@material-ui/core";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";
import UpdateEmail from "../../profile/UpdateEmail";

const ManageProfile = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={12} lg={12}>
        <UpdateProfile />
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <UpdateEmail />
      </Grid>
      <Grid item sm={12} md={12} lg={12}>
        <UpdatePassword />
      </Grid>
    </Grid>
  );
};

export default ManageProfile;
