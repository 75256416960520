import React, { useState } from "react";

import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  TextField,
  Grid,
  DialogContent,
} from "@material-ui/core";
import { GetImage, Post } from "../../../actions";
import Dialoge from "../../../components/Dialog";
import ShowPackageData from "./ViewAppPackage";
import { useHistory } from "react-router-dom";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";

import {
  Post_ChangeOrganizationName_URL,
  Post_InternalAppLogin_URL,
} from "../../../constants/apiUrls";
import EditIcon from "@material-ui/icons/Edit";
import * as Yup from "yup";
import { Form, Formik } from "formik";

const styles = makeStyles((theme) => ({
  cardRoot: {
    borderRadius: "10px",
  },
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    flexWrap: "wrap",
    width: "auto",
  },
  sizeAvatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    objectFit: "contain",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    padding: "10px",
  },
  heading: {
    textAlign: "center",
  },
  py: {
    //padding: "10px 0px",
    marginTop: "10px",
    textAlign: "center",
    //whiteSpace: "nowrap",
    height: "178px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    float: "right",
  },
  ribbon: {
    position: "absolute",
    top: "30px",
    right: "-30px",
    width: "178px",
    height: "26px",
    transform: "rotate(0.1turn)",
  },
}));

const MyAppsCard = ({ data }) => {
  const classes = styles();
  const [openPackageDialoge, setOpenPackageDialoge] = useState(false);
  const [openEditOrgDialoge, setOpenEditOrgDialoge] = useState(false);
  const [packageData, setPackageData] = useState({});
  const [selectedOrg, setSelectedOrg] = useState({});
  const [newOrgnizationName, setNewOrgnizationName] = useState();
  const history = useHistory();
  const handleLoginInternalApp = (customerId) => {
    Post(
      { customerId: customerId },
      Post_InternalAppLogin_URL,
      null,
      (resp) => {
        window.open(getLoginURL(data.appId) + `${resp.data}`, "_blank");
      },
      (error) => {}
    );
  };
  return (
    <Box boxShadow={5}>
      <Card style={{ position: "relative" }}>
        <CardContent>
          <Box
            className={classes.ribbon}
            style={{
              textAlign: "center",
              background:
                data.packageStatus === 0 || data.packageStatus === 1
                  ? "green"
                  : data.packageStatus === 2
                  ? "red"
                  : "orange",
            }}
          >
            {data.packageStatus === 1 || data.packageStatus === 0 ? (
              <>
                {data.isLifeTime ? (
                  <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                    {getTranslation(
                      "LIFE TIME ACTIVE",
                      "LIFE TIME ACTIVE",
                      "LIFE TIME ACTIVE"
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                    {getTranslation("ACTIVE", "ACTIVE", "AKTIV")}
                  </Typography>
                )}
              </>
            ) : data.packageStatus === 2 ? (
              <>
                <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                  {getTranslation("INACTIVE", "INAKTIV", "INAKTIV")}
                </Typography>
              </>
            ) : data.packageStatus === 4 ? (
              <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                {getTranslation("LIFETIME", "DURÉE DE VIE", "LEBENSDAUER")}
              </Typography>
            ) : (
              <>
                <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                  {data.remainingTrialDays}{" "}
                  {getTranslation(
                    "Day(s) Left",
                    "Jours restants",
                    "Tage übrig"
                  )}
                </Typography>
              </>
            )}
          </Box>
          <div className={classes.root}>
            <img
              className={classes.sizeAvatar}
              alt="logo"
              src={GetImage(data.appIcon)}
            />
            <Typography variant="h3">{data.appTitle}</Typography>
            <Typography variant="h5">
              {newOrgnizationName
                ? newOrgnizationName
                : data.organizationName && data.organizationName !== null
                ? data.organizationName
                : ""}
              <IconButton
                onClick={() => {
                  setOpenEditOrgDialoge(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Typography>
            <Typography className={classes.py} variant="body1">
              {data.appDescription}
            </Typography>
          </div>
        </CardContent>
        <CardActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* View Package Button */}
            <Button
              style={{
                margin: "5px",
                "&:hover": {
                  backgroundColor: "rgb(33,87,116)",
                },
              }}
              onClick={() => {
                setOpenPackageDialoge(true);
                setPackageData(data.package);
              }}
              size="small"
              color="primary"
              variant="outlined"
            >
              {getTranslation(
                "View Package",
                "Voir le forfait",
                "Paket ansehen"
              )}
            </Button>
            {data.packageStatus !== 2 && !data.isExpired ? (
              <ButtonWithLoading
                color="primary"
                style={{
                  margin: "5px",
                  "&:hover": {
                    backgroundColor: "rgb(33,87,116)",
                  },
                }}
                variant="contained"
                disabled={data.isInstalled}
                onClick={() => handleLoginInternalApp(data.customerId)}
                label={getTranslation("Login", "Connexion", "Anmeldung")}
              />
            ) : null}
            {/* Activate App */}
            {data.packageStatus === 2 ? (
              <Button
                style={{
                  margin: "5px",
                }}
                onClick={() => {
                  history.push(`checkout/${parseInt(data.id)}`);
                }}
                size="small"
                color="inherit"
                variant="contained"
              >
                {getTranslation("Activate App", "Activate App", "Activate App")}
              </Button>
            ) : // End Trial
            data.packageStatus === 3 ? (
              <Button
                style={{
                  margin: "5px",
                }}
                onClick={() => {
                  history.push(`checkout/${parseInt(data.id)}`);
                }}
                size="small"
                color="Primary"
                variant="contained"
              >
                {getTranslation(
                  "End Trial",
                  "Terminer l'essai",
                  "Versuch beenden"
                )}
              </Button>
            ) : null}
            {data.isLifeTime === false && data.packageStatus !== 3 ? (
              <Button
                style={{
                  margin: "5px",
                }}
                onClick={() => {
                  history.push(`upgrade/${data.appId}/${data.customerId}`);
                }}
                size="small"
                color="Primary"
                variant="contained"
              >
                {getTranslation("Upgrade", "Upgrade", "Upgrade")}
              </Button>
            ) : null}
            {data.packageStatus === 3 ? (
              <Button
                style={{
                  margin: "5px",
                }}
                onClick={() => {
                  history.push(`upgrade/${data.appId}/${data.customerId}`);
                }}
                size="small"
                color="Primary"
                variant="contained"
              >
                {getTranslation(
                  "Upgrade Free For Ever",
                  "Upgrade Free For Ever",
                  "Upgrade Free For Ever"
                )}
              </Button>
            ) : null}
          </Box>
        </CardActions>
        <Dialoge
          maxWidth="sm"
          fullWidth
          title="Package"
          open={openPackageDialoge}
          onClose={() => setOpenPackageDialoge(false)}
        >
          <ShowPackageData packageId={data.packageId} />
        </Dialoge>
      </Card>
      <Dialoge
        maxWidth="sm"
        fullWidth
        title="Change Organization Name"
        open={openEditOrgDialoge}
        onClose={() => setOpenEditOrgDialoge(false)}
      >
        <DialogContent style={{ padding: "50px" }}>
          <Formik
            //enableReinitialize
            initialValues={{
              title: "",
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required("This is a required Field"),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              Post(
                {
                  id: data.id,
                  title: values.title,
                },
                Post_ChangeOrganizationName_URL,
                null,
                (resp) => {
                  actions.setSubmitting(false);
                  setNewOrgnizationName(values.title);
                  setOpenEditOrgDialoge(false);
                },
                (error) => {
                  actions.setSubmitting(false);
                }
              );
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
              handleChange,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <TextField
                      value={values.title}
                      label="New Organization Name"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <ButtonWithLoading
                      label={getTranslation("Save", "Save", "Save")}
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                      style={{ margin: "0px" }}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialoge>
    </Box>
  );
};

export default MyAppsCard;

const getLoginURL = (id) => {
  if (id === 1) {
    return process.env.REACT_APP_CLIC_RENT_URL;
  } else if (id === 2) {
    return process.env.REACT_APP_MY_SERVICE_CLUB_URL;
  } else if (id === 4) {
    return process.env.REACT_APP_RESTO_CLICK_URL;
  }
};
