import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import authUtils from "../src/utils/authUtils";
import { useDispatch } from "react-redux";
import { SET_USER } from "../src/actions/types";
import { Get_CustomerAppByCustomerId_URL } from "../src/constants/apiUrls";
import { Get } from "../src/actions/apiActions";
import Animated from "../src/assests/images/Animation - 1700800458439.gif";

const AppPaymentNaviagte = () => {
  const [appId, setAppId] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const base64Data = params.get("data");

    if (base64Data) {
      const decodedData = atob(base64Data); // Decode Base64
      const parsedObject = JSON.parse(decodedData);
      console.log("decdoed", parsedObject);
      console.log("extract", parsedObject.token);

      authUtils.setSession(parsedObject.token);
      authUtils.setUserRefreshToken(
        parsedObject.refreshToken,
        parsedObject.refreshTokenExpiry
      );

      localStorage.setItem("lang", "fr");
      localStorage.setItem("backUrl", parsedObject.redirectUrlBack);
      localStorage.setItem("userAppCustumerId_", parsedObject.customerId);

      const url = localStorage.getItem("backUrl");
      const id = localStorage.getItem("userAppCustumerId_");
      const fullUrl = `${url}?id=${id}`;
      console.log("hui", fullUrl);

      dispatch({ type: SET_USER, payload: parsedObject.user });

      Get(
        {
          customerId: parsedObject.customerId,
        },
        Get_CustomerAppByCustomerId_URL,
        null,
        (resp) => {
          setAppId(resp.data.id);
          console.log(resp.data);
          if (
            parsedObject.target === "signup" &&
            resp.data.remainingTrialDays > 0
          ) {
            window.location.href = `${process.env.REACT_APP_CLIC_RENT_DOMAIN}dashboard`;
          }
          if (
            parsedObject.target === "signup" &&
            resp.data.remainingTrialDays === 0
          ) {
            window.location.href = `${process.env.REACT_APP_APP_MANAGER_DOMAIN}checkout/${resp.data.id}`;
          }
          if (parsedObject.target === "trial end") {
            window.location.href = `${process.env.REACT_APP_APP_MANAGER_DOMAIN}checkout/${resp.data.id}`;
          }
        },
        (error) => {}
      );
    }
  }, [location.search]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img alt='animation' src={Animated} width='100' height='100' />
    </div>
  );
};

export default AppPaymentNaviagte;
