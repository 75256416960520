import { Post_InitiatePayment_URL, Post_InitiateSMSPayment_URL } from "../constants/apiUrls";
import axios from "../helpers/apiHelper";


//End Points
// const RegisterCustomerAppEndPoint = "Auth/Login";
const MakePaymentEndPoint = "Installer/InitiatePayment";


export const MakePayment = (payload, history) => async (dispatch) => {
  try {
      console.log("i am running")
    const response = await axios(history).post(Post_InitiatePayment_URL, payload);
    console.log(response,"2222")
    return response;
  } catch (error) {
    return error.response;
  }
};

export const MakeSmsPayment = (payload, history) => async (dispatch) => {
  try {
      console.log("i am Sms Payment")
    const response = await axios(history).post(Post_InitiateSMSPayment_URL, payload);
    console.log(response,"2222")
    return response;
  } catch (error) {
    return error.response;
  }
};
