import { Box, Button, CircularProgress, makeStyles, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Get } from "../../../actions";
import { STRIPE_API_KEY } from "../../../constants"
import {  Post_VerifyBulkCheckoutRequest_URL } from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import BillingInfo from "../payment/BillingInfo";
import MakeAdditionalPayment from "./makeAdditionalPayment"

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));
  
export const AdditionalOrganizations = () => {
    const { model } = useParams();
    const [requestModel,setRequestModel]=useState();
    const [loading,setLoading]=useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const steps = getSteps();

    useEffect(()=>{
        Get(
            {address:model},
            Post_VerifyBulkCheckoutRequest_URL,
            null,
            resp=>{
                setLoading(false)
               
                setRequestModel(resp.data)
            },
            error=>{
                setLoading(false)

            }
        )
    },[])


    function getSteps() {
      return ["Billing Info", "Payment"];
    }
  
    function getStepContent(step) {
      switch (step) {
        case 0:
          return (
            <>
              <BillingInfo
                onBillingInfoSelected={(billingInfo) => {
                  // setBillingInfo(billingInfo)
                  setRequestModel({
                    ...requestModel,
                    billingInfoId: billingInfo?.id,
                    billingInfo: billingInfo,
                  });
                  handleNext();
                }}
              />
            </>
          );
  
        case 1:
          return (
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Elements>
                <MakeAdditionalPayment
                  installerModel={requestModel}
                  onNextStep={() => handleNext()}
                  onPreviousStep={() => handleBack()}
                />
              </Elements>
            </StripeProvider>
          );
        default:
          return "Unknown step";
      }
    }
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return ( loading ? <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        >
            <CircularProgress />
        </Box> 
        : requestModel?
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
  
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                {getTranslation(
                  "All steps completed - you",
                  "Toutes les étapes terminées - vous",
                  "Alle Schritte abgeschlossen - Sie"
                )}
                &apos;{getTranslation("re finished", "re fini", "wieder fertig")}
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                {getTranslation("Reset", "Réinitialiser", "Zurücksetzen")}
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div></div>
            </div>
          )}
        </div>
      </div>
      :

      <Alert severity="error">This link is expired <a href="#" >click here</a> to request for new link</Alert>
    );
}