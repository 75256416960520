import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import store from "./store"
import App from './App';
import "react-quill/dist/quill.snow.css"; 
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { SnackbarProvider } from 'notistack';


ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,

  document.getElementById("root")
);