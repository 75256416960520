import React from "react";
import { Formik } from "formik";
import { Grid, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import DialogActions from "../../components/DialogActions";
import DialogContent from "../../components/DialogContent";
import LoadingButton from "../../components/ButtonWithLoading";
import { RegisterCustomer, GetAllCustomers } from "../../actions";
import * as Yup from "yup";
import checkErrors from "../../utils/responseUtils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { getTranslation } from "../../heplers/translationHelper";

const AddNewCustomer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        email: Yup.string().email().required("Required"),
        userName: Yup.string().required("Required"),
        password: Yup.string()
          .min(6, "Password atleast 6 character long")
          .required("Password is required"),
        confirmPassword: Yup.string()
          .min(6, "Password atleast 6 character long")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        dispatch(RegisterCustomer(values, history)).then((res) => {
          const errors = checkErrors(res);
          if (!errors) {
            enqueueSnackbar("Customer information saved", {
              variant: "success",
            });
            dispatch(GetAllCustomers({ pageNumber: 1, pageSize: 5 }, history));
            setSubmitting(false);
            props.onClose(false);
          } else {
            errors?.map((error) =>
              enqueueSnackbar(error.message, { variant: "error" })
            );
            setSubmitting(false);
          }
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="firstName"
                  label={getTranslation("First Name", "prénom", "Vorname")}
                  value={values.firstName}
                  onChange={handleChange}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="lastName"
                  label={getTranslation("Last Name", "nom", "Nachname")}
                  value={values.lastName}
                  onChange={handleChange}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "User Name",
                    "Nom d'utilisateur",
                    "Nutzername"
                  )}
                  name="userName"
                  value={values.userName}
                  onChange={handleChange}
                  error={touched.userName && Boolean(errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation("Email", "email", "E-Mail")}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="password"
                  label={getTranslation("Password", "mot de passe", "Passwort")}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="password"
                  label={getTranslation(
                    "Confirm Password",
                    "confirmer mot de passe",
                    "Passwort bestätigen"
                  )}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              color="primary"
              label={getTranslation("Save", "sauvegarder", "Speichern")}
              loading={isSubmitting}
              type="submit"
              variant="contained"
            />
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AddNewCustomer;
