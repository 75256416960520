import {
  LOADING_CUSTOMERS,
  CUSTOMERS_DATA,
  CUSTOMERS_ERRORS,
  MY_APP_DATA
} from "../actions/types";

const initState = {
  loading: false,
  errors: null,
  customers: [],
  myApps:[]
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case CUSTOMERS_DATA:
      return {
        ...state,
        loading: false,
        errors: null,
        customers: action.payload,
      };
    case MY_APP_DATA:
      return {
        ...state,
        loading: false,
        errors: null,
        myApps: action.payload,
      };
    case CUSTOMERS_ERRORS:
      return {
        ...state,
        customers: [],
        loading: false,
        errors: action.payload,
      };
    default:
      return { ...state };
  }
};

export default userReducer;
