import { Typography, Link } from "@material-ui/core";
import { getTranslation } from "../heplers/translationHelper";

export default function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {getTranslation(
        "Copyright © Impact Soft",
        "Droits d'auteur © Impact Soft",
        "Copyright © Auswirkungen weich"
      )}{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
