import {
 EMAIL_SETTINGS_ERROR,
 EMAIL_SETTINGS
} from "../actions/types";
const initState = {
  errors: null,
  emailSettings: { id: 0, hostname: "", username: "", password: "", port: "" },
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case EMAIL_SETTINGS:
      return {
        ...state,

        errors: null,
        emailSettings: action.payload,
      };
    case EMAIL_SETTINGS_ERROR:
      return {
        ...state,
        emailSettings: null,
       
        errors: action.payload,
      };
   
    default:
      return { ...state };
  }
};

export default userReducer;
