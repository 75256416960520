import React, { useEffect, useState } from "react";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { getTranslation } from "../../heplers/translationHelper";

import { Get } from "../../actions";
import { Get_VerifyConfirmationEmail_URL } from "../../constants/apiUrls";
export const ConfirmationEmail = () => {
  const { token, appuserid } = useParams();
  const history = useHistory();
  const [verifyingEmail, setVerifyingEmail] = useState(true);
  const [isVerified, setisVerified] = useState(false);
  useEffect(() => {
    Get(
      { token: token, appUserId: appuserid },
      Get_VerifyConfirmationEmail_URL,
      null,
      (resp) => {
        setVerifyingEmail(false);
        setisVerified(true);
      },
      (error) => {
        setVerifyingEmail(false);
      }
    );
  }, [token]);
  return verifyingEmail ? (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress />
      <Typography>
        {getTranslation(
          "We are verifying your email. Please wait",
          "Nous vérifions votre e-mail. S'il vous plaît, attendez",
          "Wir überprüfen Ihre E-Mail. Warten Sie mal"
        )}{" "}
      </Typography>
    </Grid>
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <EmailOutlinedIcon
        color="secondary"
        style={{
          width: "350px",
          height: "100px",
        }}
      />
      <br />
      <br />
      {isVerified ? (
        <Typography>
          {" "}
          {getTranslation(
            "Thanks for email verification.",
            "Merci pour la vérification par e-mail.",
            "Vielen Dank für die E-Mail-Bestätigung."
          )}{" "}
        </Typography>
      ) : (
        <Typography>
          {getTranslation(
            "Email verification failed.",
            "La vérification de l'adresse e-mail a échoué.",
            "E-Mail-Bestätigung fehlgeschlagen."
          )}{" "}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/login")}
      >
        Login
      </Button>
    </Grid>
  );
};
