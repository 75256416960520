import { URL } from ".";

export const Get_AllCustomersWithPagination_URL =
  "Auth/GetAllUsersWithPagination";

export const Get_AllUserActivitiesByVisitorIdWithPagination_URL =
  "UserActivity/GetAllUserActivitiesByVisitorIdWithPagination";
export const Get_AllUsersActivitiesWithPagination_URL =
  "UserActivity/GetAllUsersActivitiesWithPagination";

export const Get_RegisteredUsers_URL = `Auth/GetRegisteredUsers`;
export const Post_ResendConfirmationEmail_URL = `${URL}auth/resendconfirmationemail`;
export const Get_MultipleBillingInfo_URL = `${URL}BillingInfo/GetAllBillingInfo`;
export const Post_InsertBillingInfo_URL = `${URL}BillingInfo/InsertBillingInfo`;
export const Post_UpdateBillingInfo_URL = `${URL}BillingInfo/UpdateBillingInfo`;
export const Post_DeleteBillingInfo_URL = `${URL}BillingInfo/DeleteBillingInfo`;
export const Get_ValidateEuropeVAT_URL = `${URL}BillingInfo/ValidateEuropeVAT`;
export const Post_Install_URL = `${URL}Installer/Install`;
export const Post_InitiatePayment_URL = `${URL}Installer/InitiatePayment`;
export const Post_Upgrade_URL = `${URL}Installer/Upgrade`;
export const Post_AddCustomOrganizations_URL = `${URL}Installer/AddCustomOrganizations`;
export const Post_VerifyBulkCheckoutRequest_URL = `${URL}Installer/VerifyBulkCheckoutRequest`;
export const Post_MultiOrganizationPayment_URL = `${URL}Installer/MultiOrganizationPayment`;
export const Get_InvoiceDraft_URL = `${URL}Installer/GetInvoiceDraft`;
export const Post_ConfirmPayment_URL = `${URL}Installer/ConfirmPayment`;
export const Post_ConfirmMultiOrganizationPayment_URL = `${URL}Installer/ConfirmMultiOrganizationPayment`;

export const Post_ConfirmSMSPayment_URL = `${URL}Sms/ConfirmSMSPayment`;
export const Post_InitiateSMSPayment_URL = `${URL}Sms/InitiateSMSPayment`;

export const Get_CustomerAppPackage_URL = `${URL}Packages/GetCustomerAppPackage`;
export const Get_AllPackagesOfApp_URL = `${URL}Packages/GetAllExternalPackages`;
export const Get_DeletePackage_URL = `${URL}Packages/DeletePackage`;
export const Get_CustomerAppByCustomerId_URL = `${URL}Customers/GetCustomerAppByCustomerId`;

export const Get_VerifyConfirmationEmail_URL = `${URL}Auth/VerifyConfirmationEmail`;
export const Post_InternalAppLogin_URL = `${URL}Auth/InternalAppLogin`;
export const Post_ExternalAppLogin_URL = `${URL}Auth/ExternalLogin`;

export const GetCustomerAppEndPoint = `${URL}Customers/GetCustomerApp`;
export const Get_AllCustomersList_URL = `${URL}Customers/GetAllCustomersList`;
export const Post_ChangeOrganizationName_URL = `${URL}Customers/ChangeOrganizationName`;
export const Get_AllCustomerApps_URL = `${URL}Customers/GetAllCustomerApps`;

export const GetPackageEndPoint = `${URL}Packages/GetPackage`;
export const Get_AppsLogos_URL = `${URL}Apps/GetAppsLogos`;
export const Get_AppsList_URL = `Apps/GetAppsList`;

// Language

export const Get_LanguagesWithPagination_URL = `${URL}Language/GetLanguagesWithPagination`;
export const Get_LanguageDictionary_URL = `${URL}Language/GetLanguageDictionary`;
export const Post_AddNewText_URL = `${URL}Language/AddNewText`;
export const Post_UpdateTextTranslation_URL = `${URL}Language/Update`;

// Email Service
export const Post_SendEmail_URL = `${URL}EmailService/SendEmail`;
export const Get_ClearAllSMS_URL = `${URL}Sms/ClearAllSMS`;
export const Get_AllPendingSMS_URL = `${URL}Sms/GetAllPendingSMS`;
export const Post_DeleteSelectedSMS_URL = `${URL}Sms/DeleteSelected`;

// export const Get_BillingInfo_URL = "http://appmanagerapi.impact-soft.com/BillingInfo/GetBillingInfo"
// export const Get_BillingInfo_URL = "http://appmanagerapi.impact-soft.com/BillingInfo/GetBillingInfo"
// export const Get_BillingInfo_URL = "http://appmanagerapi.impact-soft.com/BillingInfo/GetBillingInfo"
