import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { Get, MakePayment, Post } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import checkErrors from "../../../utils/responseUtils";
import LoadingButton from "../../../components/ButtonWithLoading";
import securelogo from "../../../assests/images/Poweredbystripe.png";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import {
  GetCustomerAppEndPoint,
  Get_CustomerAppPackage_URL,
  Post_ConfirmMultiOrganizationPayment_URL,
  Post_MultiOrganizationPayment_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { countriesWithCode } from "../../../constants/countries";
import { EuropeanCountries } from "../payment/CreateBillingInfoDialog";

const AdditionalPaymentCheckOut = ({ installerModel, onPreviousStep, ...rest }) => {
  const history = useHistory();
  const props = rest;
  const [errorMessage, setErrorMessage] = useState(null);
  const { loading, errors, packageData } = useSelector(
    (state) => state.packages
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [isError, setIsError] = useState({ error: false, errorMessage: "" });
  const { customerRegisteredAppId } = useParams();
  const [customerApp, setCustomerApp] = useState({});

  useEffect(()=>{
    let resp={}
    resp.packagePaymentType=installerModel?.packagePaymentType
    const european=EuropeanCountries[installerModel?.billingInfo.country]
    const total=installerModel?.totalNet
    let vat21Value = 0;
    resp.currentVat = 0
    let amountWithoutVat = total
    if (process.env.REACT_APP_VATREGULATIONTYPE_URL == "normal") {
      if (installerModel?.billingInfo.isVATVerified == true) {
        if (european && european.key == "BE") {
          vat21Value = total * (european.vat/100)
          amountWithoutVat = total
          resp.currentVatValue = vat21Value
          resp.currentVat = european.vat
        }
        else
          {
            resp.currentVat =0
            resp.currentVatValue = vat21Value
          }
      }
      else {
       
        // Case 1: Normal Regulation and Customer has not valid vat
        if (european) {
          vat21Value = total * (european.vat/100)

          amountWithoutVat = total
          resp.currentVatValue = vat21Value
          resp.currentVat = european.vat
        }
        else
          {
            resp.currentVat =0
            resp.currentVatValue = vat21Value
          }
      }
    }else{
      if (installerModel?.billingInfo.isVATVerified == true) {
        if (european && european.key == "BE") {
          vat21Value = total * (european.vat/100)
          amountWithoutVat = total
          resp.currentVatValue = vat21Value
          resp.currentVat = european.vat
        }
        else if(european){
          resp.currentVat =0
          amountWithoutVat = total
          resp.currentVatValue = 0
        }
        else
          {
            resp.currentVat =0
            resp.currentVatValue = vat21Value
        }
      } else {

        if (european) {
          vat21Value =  total * 0.21
          amountWithoutVat = total
          resp.currentVatValue = vat21Value
          resp.currentVat =21
        }
        else
        resp.currentVat =0
        resp.currentVatValue = vat21Value
      }
    }
    resp.totalToPay = resp?.currentVatValue + amountWithoutVat;
    resp.amountWithoutVat = amountWithoutVat;
    resp.maintenanceCharges=resp.totalToPay*0.10
    console.log("ajkldhaskjdhasjkldhla",resp)
    setCustomerApp(resp)
  },[])


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    installerModel.countryCode=countriesWithCode.find(x=>x.name==installerModel?.billingInfo?.country).code

    const cardElementRef = props.elements.getElement("cardNumber");
    try {
      let paymentMethod = await props.stripe.createPaymentMethod({
        type: "card",
        card: cardElementRef,
      });
      if (paymentMethod) {
          Post(
              {
                  ...installerModel,
                  paymentToken: paymentMethod?.paymentMethod?.id,
                  vat:customerApp?.currentVat
              },
              Post_MultiOrganizationPayment_URL,
              null,
              async res => {
                if(res.data.status=="3ds"){
                  const confirmPayment = await props.stripe.confirmCardPayment(
                    res.data?.data?.client_secret,
                    { payment_method: { card: cardElementRef } }
                  );
                  if (confirmPayment.paymentIntent.status == "succeeded") {
                    
                    await Post(
                      {
                        ...installerModel,
                        countryCode: installerModel?.billingInfo?.countryCode,
                        amount: customerApp?.totalToPay,
                        paymentToken: paymentMethod?.paymentMethod?.id,
                        // customerRegisteredAppId: parseInt(customerRegisteredAppId),
                        subscriptionId:res?.data?.subscriptionId
                      },
                      Post_ConfirmMultiOrganizationPayment_URL,
                      null,
                      status => {
                        enqueueSnackbar(status.data, { variant: "success" })
                        history.push("/myapps");
                        setSubmitting(false);
                      },
                      error => {
                        enqueueSnackbar(error.data, { variant: "success" })
      
                      }
                    )
                  }else{
                    enqueueSnackbar("We were not able to make the payments using this card, Please try another card or contact your bank for more details about the issue.", { variant: "error" })
                    // history.push("/myapps");
                    setSubmitting(false);
                  }
                }else{
                  enqueueSnackbar("Your payment made successfully, We had also created your organizations.")
                  history.push("/myapps");
                  setSubmitting(false);
                }
              },
              error => {
                  enqueueSnackbar("We are unable to process your payment, please check your information and try again.")

              }
          )
      }
    } catch (error) {
      enqueueSnackbar("Invalid card information", { variant: "error" });
    }
  };
  return (
    <Card>
      {errorMessage ? (
        <CardContent>
          <Box
            my={5}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" color="initial ">
              {errorMessage}
            </Typography>
          </Box>
        </CardContent>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <CardContent>
              {isError.error ? (
                <Alert severity="error">{isError.errorMessage}</Alert>
              ) : null}
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card
                  >
                    <CardHeader
                      title={getTranslation(
                        "Summary",
                        "Résumé",
                        "Zusammenfassung"
                      )}
                    />
                    <CardContent>
                      <Typography variant="h4">
                        {getTranslation("Package", "Accueil", "Startseite")}
                      </Typography>
                      <Table>
                        <TableBody>
                        <TableRow>
                            <TableCell>
                              {getTranslation("Your App", "Your App", "Your App")}
                            </TableCell>
                            <TableCell>{installerModel?.appName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getTranslation(
                                "Package Name",
                                "Nom du paquet",
                                "Paketnamen"
                              )}
                            </TableCell>
                            <TableCell>{installerModel?.packageName}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getTranslation("Price", "Prix", "Preis")} (Per Organization)
                            </TableCell>
                            <TableCell align="right">{(installerModel?.price).toFixed(2)} &euro; </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell >
                              {getTranslation("Quantity", "Quantity", "Quantity")}
                            </TableCell>
                            <TableCell align="right">{(installerModel?.quantity).toFixed(2)}</TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell>
                                <strong>
                              {getTranslation("Total Amount", "Total Amount", "Total Amount")}</strong>
                            </TableCell>
                            <TableCell align="right"><strong>{(installerModel?.quantity * installerModel?.price).toFixed(2)}</strong> &euro; </TableCell>
                          </TableRow> */}
                          <TableRow>
                          <TableCell>
                           
                              {getTranslation(
                                "Discount ( % )",
                                "Discount ( % )",
                                "Discount ( % )"
                              )}
                            </TableCell>
                            <TableCell align="right">{(installerModel?.discount).toFixed(2)} %</TableCell>
                          </TableRow>
                          {customerApp?.packagePaymentType==2 ?
                          <TableRow>
                            <TableCell>
                              {getTranslation(
                                "Yearly Maintenance Charges (Incl.)",
                                "Yearly Maintenance Charges (Incl.)",
                                "Yearly Maintenance Charges (Incl.)"
                              )}
                            </TableCell>
                            <TableCell  align="right">{customerApp?.maintenanceCharges.toFixed(2)} &euro;</TableCell>
                          </TableRow>
                          :null}
                          <TableRow>
                          <TableCell>
                            <strong>
                              {getTranslation(
                                "Sub total",
                                "Sub total",
                                "Sub total"
                              )}</strong>
                            </TableCell>
                            <TableCell align="right"><strong>{(customerApp?.amountWithoutVat)?.toFixed(2)} </strong></TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getTranslation(
                                "VAT",
                                "TVA",
                                "Mehrwertsteuer"
                              )+"("+customerApp?.currentVat+"% "+getTranslation(
                                "on",
                                "on",
                                "on"
                              )+" "+ customerApp?.amountWithoutVat?.toFixed(2)} &euro;{")"}
                            </TableCell>
                            <TableCell align="right">{customerApp?.currentVatValue?.toFixed(2)} &euro;</TableCell>
                          </TableRow>
                          
                        
                          <TableRow>
                            <TableCell><strong>
                              {getTranslation(
                                "Total Net Amount",
                                "Total Net Amount",
                                "Total Net Amount"
                              )}</strong>
                            </TableCell>
                            <TableCell align="right"><strong>{(customerApp?.totalToPay)?.toFixed(2)} &euro;</strong></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <br />
                      <Box
                        // display="flex"
                        // flexDirection="column"
                        // alignItems="flex-start"
                        // justifyContent="center"
                      >
                        <br />
                        <Typography variant="h4">
                          {getTranslation(
                            "Billing Info:",
                            "Informations de facturation:",
                            "Rechnungsinformation:"
                          )}
                        </Typography>
                        <br />
                        <Typography variant="body2">
                          {getTranslation("Title:", " Titre", "Titel")} &nbsp;
                          {installerModel?.billingInfo.title}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation("Name:", "Nom:", " Name:")}&nbsp;
                          {installerModel?.billingInfo.firstName}{" "}
                          {installerModel?.billingInfo.lastName}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation(
                            "Company:",
                            "Société:",
                            "Unternehmen:"
                          )}
                          &nbsp;{installerModel?.billingInfo.companyName}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation(
                            "Phone Number:",
                            "Numéro de téléphone:",
                            "Telefonnummer:"
                          )}
                          &nbsp;
                          {installerModel?.billingInfo.phoneNumber}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation("Town:", "Ville:", "Stadt, Dorf:")}
                          &nbsp;{installerModel?.billingInfo.town}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation(
                            " Zipcode:",
                            "Code postal:",
                            " Postleitzahl:"
                          )}
                          &nbsp;{installerModel?.billingInfo.zipCode}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation(
                            "Address:",
                            "Adresse:",
                            "Die Anschrift:"
                          )}
                          &nbsp;{installerModel?.billingInfo.address}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation("Country:", "Pays:", "Land:")}&nbsp;
                          {installerModel?.billingInfo.country}
                        </Typography>
                        <Typography variant="body2">
                          {getTranslation("Vat: ", "T.V.A:", "Mehrwertsteuer:")}
                          &nbsp;{installerModel?.billingInfo.vat}
                        </Typography>
                        <br />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ width: "100%"}}>
                    <CardHeader title="Payment Info" />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item sm={12} md={12} lg={12}>
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography variant="body1">
                              {getTranslation(
                                "Pay Via",
                                "Payer via",
                                "Zahlen Sie über"
                              )}
                            </Typography>
                            <Box>
                              <img
                                src={securelogo}
                                width="150"
                                height="30"
                                alt="visa card and mastercard logo png"
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                          <Typography color="textPrimary">
                            {getTranslation(
                              " Card Number",
                              "Numéro de carte",
                              "Kartennummer"
                            )}
                          </Typography>
                          <Box
                            mt={1}
                            border={`1px solid gray`}
                            p={1.3}
                            borderRadius="10px"
                          >
                            <CardNumberElement />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                          <Typography color="textPrimary">
                            {getTranslation(
                              "Expiry Date",
                              "Date d'expiration",
                              "Verfallsdatum"
                            )}
                          </Typography>

                          <Box
                            border={`1px solid gray`}
                            p={1}
                            mt={1}
                            borderRadius="10px"
                          >
                            <CardExpiryElement />
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6} lg={6}>
                          <Typography color="textPrimary">CVC code</Typography>

                          <Box
                            mb={2}
                            border={`1px solid gray`}
                            mt={1}
                            p={1}
                            borderRadius="10px"
                          >
                            <CardCVCElement />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <LoadingButton
                          color="primary"
                          label={"Pay " + (customerApp?.totalToPay)?.toFixed(2) + " €"}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          size="small"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            {/* <CardActions>
              <Box
                pr={1}
                pt={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
              >
                <ButtonWithLoading
                  label={getTranslation("Back", "Zurück", "Zurück")}
                  size="small"
                  variant="outlined"
                  color="primary"
                  style={{
                    marginRight: "89%",
                  }}
                  onClick={onPreviousStep}
                />
              </Box>
            </CardActions> */}
          </form>
        </>
      )}
    </Card>
  );
};

export default injectStripe(AdditionalPaymentCheckOut);
