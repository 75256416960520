import React, { useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
  Menu,
  Link,
} from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import authUtils from "../../utils/authUtils";
import useStyles from "../../assests/styles/layout/TopBar";
import { useSelector } from "react-redux";
import { getTranslation } from "../../heplers/translationHelper";

import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../icons";
import { ContactSupport, Label } from "@material-ui/icons";

const TopBar = ({ className, onMobileNavOpen, history, ...rest }) => {
  const theme = useTheme();
  const handleLogout = () => {
    authUtils.logout();
    history.push("/login");
  };
  const userData = useSelector((state) => state.auth.user);

  const handleContactUs = () => {
    window.open("https://form.jotform.com/220413974982361", "_blank");
  };

  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  const handleLanguageSelection = (e) => {
    localStorage.setItem("lang", e.target.value);
    window.location.reload(true);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileAnchorEl(event.currentTarget);
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
    setMobileMenuOpen(false);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {userData?.roles == "SuperAdmin" || userData?.roles == "Admin" ? (
          ""
        ) : (
          <Typography href='/customerCredit' variant='h5' component='a'>
            {getTranslation("Balance", "Balance", "Balance") +
              " " +
              (!userData?.smsCredit ? 0 : userData?.smsCredit.toFixed(2)) +
              " €"}
          </Typography>
        )}
      </MenuItem>
      <MenuItem>
        <Select
          style={{
            marginRight: "20px",
            width: "160px",
            height: "34px",
            borderRadius: "17px",
          }}
          variant='outlined'
          defaultValue={localStorage.getItem("lang")}
          onChange={handleLanguageSelection}
        >
          <MenuItem value='en'>
            <IconButton style={{ marginRight: "10px" }}>
              <EnglishLanguageIcon />
            </IconButton>
            English
          </MenuItem>
          <MenuItem value='fr'>
            <IconButton style={{ marginRight: "10px" }}>
              <FrenchLanguageIcon />
            </IconButton>
            French
          </MenuItem>
          <MenuItem value='gr'>
            <IconButton style={{ marginRight: "10px" }}>
              <GermanLanguageIcon />
            </IconButton>
            German
          </MenuItem>
        </Select>
      </MenuItem>
      <MenuItem onClick={handleContactUs}>
        <IconButton>
          <HelpOutlineOutlinedIcon />
        </IconButton>
        {getTranslation("Contact Us")}
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton>
          <InputIcon />
        </IconButton>
        {getTranslation("Logout", "Se déconnecter", "Ausloggen")}
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      className={classes.root}
      style={isMobile ? { width: "100%" } : { width: `calc(100% - ${256}px)` }}
      {...rest}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box
          display='flex'
          justifyContent='flex-end'
          alignItems={isMobile ? "flex-start" : "flex-end"}
          width='100%'
          flexDirection='row'
          textAlign={isMobile ? "center" : "initial"}
        >
      {isMobile?"":
      <Typography style={isMobile ? { marginBottom: '10px' } : { marginRight: '200px' }}>
        {getTranslation('Need help? Call Us', 'Need help? Call Us', 'Need help? Call Us')}
        <strong>+32 496 270 899</strong>
        {getTranslation('or Send us an email on', 'or Send us an email on', 'or Send us an email on')}
        <strong> support@qollex.com</strong>
      </Typography>
      }

          {isMobile ? (
            <>
              <IconButton
                onClick={handleMobileMenuOpen}
                aria-label='mobile menu'
              >
                <MenuIcon />
              </IconButton>
              {renderMobileMenu}
            </>
          ) : (
            <>
              <IconButton
                className={classes.helpButton}
                onClick={handleContactUs}
                style={{ marginRight: "15px" }}
              >
                <Tooltip title={getTranslation("Contact Us")} arrow>
                  <HelpOutlineOutlinedIcon fontSize='large' />
                </Tooltip>
              </IconButton>

              {userData?.roles == "SuperAdmin" || userData?.roles == "Admin" ? (
                ""
              ) : (
                <Typography
                  style={{ color: "black", textAlign: "center" }}
                  href='/customerCredit'
                  variant='h5'
                  component='a'
                >
                  {getTranslation("Balances", "Balances", "Balances") +
                    " " +
                    (!userData?.smsCredit
                      ? 0
                      : userData?.smsCredit.toFixed(2)) +
                    " €"}
                </Typography>
              )}

              <Select
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  width: "160px",
                  height: "34px",
                  borderRadius: "17px",
                }}
                variant='outlined'
                defaultValue={localStorage.getItem("lang")}
                onChange={handleLanguageSelection}
              >
                <MenuItem value='en'>
                  <IconButton style={{ marginRight: "10px" }}>
                    <EnglishLanguageIcon />
                  </IconButton>
                  English
                </MenuItem>
                <MenuItem value='fr'>
                  <IconButton style={{ marginRight: "10px" }}>
                    <FrenchLanguageIcon />
                  </IconButton>
                  French
                </MenuItem>
                <MenuItem value='gr'>
                  <IconButton style={{ marginRight: "10px" }}>
                    <GermanLanguageIcon />
                  </IconButton>
                  German
                </MenuItem>
              </Select>

              <Button
                className={classes.menuButton}
                color='inherit'
                onClick={handleLogout}
              >
                <InputIcon />
                {getTranslation("Logout", "Se d�connecter", "Ausloggen")}
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default withRouter(TopBar);
