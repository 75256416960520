import { colors, makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
	},
	wrapper: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		paddingTop: 64,
		[theme.breakpoints.up("lg")]: {
			paddingLeft: 256,
		},
	},
	contentContainer: {
		minHeight: "90vh",
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		padding: theme.spacing(2, 3),
	},
	content: {
		flex: "1 1 auto",
		padding: 30,
		height: "100%",
		overflow: "auto",
	},
	notificationBar: {
		width: "100%",
		padding: theme.spacing(0.2),
		backgroundColor: "rgb(68,114,138)",
		mozBoxShadow: "0 0 5px rgba(0,0,0,0.1)",
		webkitBoxShadow: "0 0 5px rgba(0,0,0,0.1)",
		boxShadow: "0 0 5px rgba(0,0,0,0.1)",
		borderRadius: theme.spacing(1),
		textAlign: "center",
		height: "40px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
}));
