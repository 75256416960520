import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import {
  CreateButton,
  DeleteButton,
  EditButton,
} from "../../../components/ButttonsWithIcons";
import { Get, Post } from "../../../actions";
import {
  Get_MultipleBillingInfo_URL,
  Post_DeleteBillingInfo_URL,
  Post_InsertBillingInfo_URL,
  Post_UpdateBillingInfo_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { CreateBillingInfoDialog } from "./CreateBillingInfoDialog";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";

const BillingInfo = ({ onBillingInfoSelected }) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  /// billingInfo give multiple Billing infos
  const [billingInfo, setBillingInfo] = useState([]);
  const [editBillingInfo, setEditBillingInfo] = useState(null);
  const [selectedBillingInfo, setSelectedBillingInfo] = useState(null);
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    Get(
      {},
      Get_MultipleBillingInfo_URL,
      null,
      (resp) => {
        console.log("sjhgkjsbgfxh", "resp", resp);
        setEditBillingInfo(resp?.data[0])
      },
      (error) => {
        console.log("sjhgkjsbgfxh", "error", error);
      }
    );
  }, []);

  const handleSubmitBillingInfo = (values, actions) => {
    Post(
      values,
      editBillingInfo ? Post_UpdateBillingInfo_URL : Post_InsertBillingInfo_URL,
      null,
      (resp) => {
        if (editBillingInfo) {
          try {
            const arr = billingInfo;
            const index = arr.findIndex((x) => x.id === values.id);
            arr[index] = values;
            setBillingInfo(arr);
            setEditBillingInfo(values);
            enqueueSnackbar("Billing Address Updated", { variant: "success" });
            onBillingInfoSelected(values)
          } catch (error) {
            console.log("ksgdfjkgsbgfg", error);
          }
        } else {
          const arr = billingInfo;
          arr.push(resp?.data);
          setBillingInfo(arr);
          enqueueSnackbar("Billing Address Added", { variant: "success" });
          setEditBillingInfo(resp?.data[0]);
          onBillingInfoSelected(values)
        }
        setOpenCreateDialog(false);
      },
      (error) => {
        enqueueSnackbar("Failed to save billing info", { variant: "error" });
        setOpenCreateDialog(false);
      }
    );
  };

  const handleEditBillingInfo = (row) => {
    setOpenCreateDialog(true);
    setEditBillingInfo(row);
  };

  const handleRadioButton = (info) => {
    setSelectedBillingInfo(info);
  };

  const handleDeleteBillingInfo = (row) => {
    setDeleting(true);
    Post(
      { billingInfoId: row.id },
      Post_DeleteBillingInfo_URL,
      null,
      (resp) => {
        setDeleting(false);
        setBillingInfo(billingInfo.filter((x) => x.id !== row.id));
        enqueueSnackbar(getTranslation("Billing Info Deleted","Billing Info Deleted","Billing Info Deleted"), { variant: "success" });
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    );
  };
  return (
    <>
      <Card>
        <CardHeader
          title={getTranslation(
            "Billing Info",
            "Informations de facturation",
            "Rechnungsinformation"
          )}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {console.log("kjdfjkbdjfbsdf", billingInfo)}
              {billingInfo ? null : (
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    margin: "10px",
                  }}
                >
                  {getTranslation(
                    "Add Billing Info",
                    "Ajouter des informations de facturation",
                    "Rechnungsinformationen hinzufügen"
                  )}
                </Typography>
              )}
            </Grid>
            
           
          </Grid>
          <br />

          <CreateBillingInfoDialog
            editBillingInfo={editBillingInfo}
            isOpen={openCreateDialog}
            onClose={() => setOpenCreateDialog(false)}
            onSubmit={(values, actions) => {
              handleSubmitBillingInfo(values, actions)

            }}
          />
          <br />
          <br />
          
        </CardContent>
      </Card>
      
    </>
  );
};
export default BillingInfo;
