
import axios from "../helpers/apiHelper";
import { PACKAGES_DATA,PACKAGES_LOADING,PACKAGES_ERROR,PACKAGES_EDIT,PACKAGE_DATA} from "./types";

//End Points
// const RegisterCustomerAppEndPoint = "Auth/Login";
const InsertPackageEndPoint = "Packages/InsertPackage";
const UpdatePackageEndPoint = "Packages/UpdatePackage";
const GetPackagesEndPoint = "Packages/GetAllPackages";
const GetAllExternalPackagesEndPoint = "Packages/GetAllExternalPackages";
const GetPackageEndPoint = "Packages/GetPackage";


export const InsertNewPackage = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(InsertPackageEndPoint, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const UpdatePackage = (payload, history) => async (dispatch) => {
  try {
    console.log("jkladhjasdhashd",payload)
    const response = await axios(history).post(UpdatePackageEndPoint, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GetAllPackags = (query, history) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGES_LOADING });
    const response = await axios(history).get(GetPackagesEndPoint, {
      params: query,
    });
   
     dispatch({ type: PACKAGES_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: PACKAGES_ERROR, payload: error.response });
  }
};
export const GetAllMarketPackages = (query, history) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGES_LOADING });
    const response = await axios(history).get(GetAllExternalPackagesEndPoint, {
      params: query,
    });
   
     dispatch({ type: PACKAGES_DATA, payload: response.data });
  } catch (error) {
    dispatch({ type: PACKAGES_ERROR, payload: error.response });
  }
};

export const PackageEdit = (row) => async (dispatch) => {
  dispatch({ type: PACKAGES_EDIT, payload: row });
};

export const getPackageById = (id,history)=>async dispatch=>{
 try {
   dispatch({ type: PACKAGES_LOADING });
   const response = await axios(history).get(GetPackageEndPoint, {
     params: {
       packageId: id,
     },
   });

   dispatch({ type: PACKAGE_DATA, payload: response.data });
 } catch (error) {
   dispatch({ type: PACKAGES_ERROR, payload: error.response });
 }

}