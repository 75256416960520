import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { Get, GetAllCustomers } from "../../actions";
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../heplers/translationHelper";
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import CardComponent from "../../components/Card";
import SearchBar from "material-ui-search-bar";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import {
  Get_AllCustomersWithPagination_URL,
  Get_AllUsersActivitiesWithPagination_URL,
  Get_AppsList_URL,
  Get_RegisteredUsers_URL,
} from "../../constants/apiUrls";
import { customerStyle } from "./CustomersTracking";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "../../heplers/format";
import moment from "moment";
import { useSnackbar } from "notistack";

const columns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Name", "Nom", "Name"),
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "apps",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Apps", "Apps", "Apps"),
    component: ({ row }) => {
      return (
        <div>
          <tr>
            <td>
              <Typography>
                {getTranslation("clicrent ", "clicrent", "clicrent")}
              </Typography>
            </td>
            <td>
              <Typography>: {row?.clicRentAppCount}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography>
                {getTranslation("Restoc", "Restoclic", "Restoclic")}
              </Typography>
            </td>
            <td>
              <Typography> : {row?.restoclicAppCount}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography>
                {getTranslation("Aureto   ", "Aureto", "Aureto")}
              </Typography>
            </td>
            <td>
              <Typography>: {row?.auretoAppCount}</Typography>
            </td>
          </tr>
        </div>
      );
    },
  },
  {
    id: "lastLogin",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Last Login", "Last Login", "Last Login"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>{format("dateTime", row?.lastLogin)}</Typography>
        </div>
      );
    },
  },
];

const Customers = ({ onCreate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = customerStyle();
  const [searchString, setSearchString] = useState("");
  const [selectedApp, setSelectedApp] = useState();
  const [packageStatus, setPackageStatus] = useState("all");
  const [apps, setApps] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentWeekData, setCurrentWeekData] = useState();
  const [currentMonthData, setCurrentMonthData] = useState();
  const [lastMonthData, setLastMonthData] = useState();
  const [page, setPage] = useState(0);
  let pageSize = localStorage.getItem("sizeCustomer");
  if (pageSize == null) {
    localStorage.setItem("sizeCustomer", 10);
    pageSize = 10;
  }
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const { enqueueSnackbar } = useSnackbar();

  let currentDate = moment();
  let weekStart = currentDate.clone().startOf("week").format("YYYY-MM-DD");
  let weekEnd = currentDate.clone().endOf("week").format("YYYY-MM-DD");
  const startOfCurrentMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD hh:mm");
  const endOfCurrentMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");
  const startOfLastMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfLastMonth = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD");

  useEffect(() => {
    getAllApps();
    getCurrentWeekLogs();
    getCurrentMonthLogs();
    getLastMonthLogs();
  }, []);

  // const { customers } = useSelector((state) => state.customers);

  useEffect(() => {
    setIsLoading(true);
    Get(
      {
        searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
        activeStatus: packageStatus,
        appId:selectedApp?.id
      },
      Get_AllCustomersWithPagination_URL,
      null,
      (resp) => {
        setCustomers(resp?.data);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(true);
      }
    );
  }, [page, rowsPerPage, searchString, packageStatus,selectedApp]);

  const getAllApps = () => {
    Get(
      {},
      Get_AppsList_URL,
      null,
      (resp) => {
        setApps(resp.data);
      },
      (error) => {}
    );
  };

  const getCurrentWeekLogs = () => {
    Get(
      {
        fromDate: weekStart,
        toDate: weekEnd,
      },
      Get_RegisteredUsers_URL,
      null,
      (resp) => {
        setCurrentWeekData(resp?.data?.registeredCount);
      },
      (error) => {
        enqueueSnackbar(error?.data ? error?.data : "internal server error", {
          variant: "error",
        });
      }
    );
  };

  const getCurrentMonthLogs = () => {
    Get(
      {
        fromDate: startOfCurrentMonth,
        toDate: endOfCurrentMonth,
      },
      Get_RegisteredUsers_URL,
      null,
      (resp) => {
        setCurrentMonthData(resp?.data?.registeredCount);
      },
      (error) => {
        enqueueSnackbar(error?.data ? error?.data : "internal server error", {
          variant: "error",
        });
      }
    );
  };

  const getLastMonthLogs = () => {
    Get(
      {
        fromDate: startOfLastMonth,
        toDate: endOfLastMonth,
      },
      Get_RegisteredUsers_URL,
      null,
      (resp) => {
        setLastMonthData(resp?.data?.registeredCount);
      },
      (error) => {
        enqueueSnackbar(error?.data ? error?.data : "internal server error", {
          variant: "error",
        });
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeCustomer", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeCustomer"));
    setPage(0);
  };

  return (
    <div>
      <CardComponent
        title={getTranslation("Customers", "Customers", "Customers")}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xm={12} md={4} xl={4}>
              <Card
                style={{
                  margin: "20px",
                  flexWrap: "wrap",
                }}
              >
                <CardContent>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {getTranslation(
                      "Current Week Registrations",
                      "Current Week Registrations",
                      "Current Week Registrations"
                    )}
                  </Typography>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {currentWeekData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xm={12} md={4} xl={4}>
              <Card
                style={{
                  margin: "20px",
                  flexWrap: "wrap",
                }}
              >
                <CardContent>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {getTranslation(
                      "Current Month Registrations",
                      "Current Month Registrations",
                      "Current Month Registrations"
                    )}
                  </Typography>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {currentMonthData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xm={12} md={4} xl={4}>
              <Card
                style={{
                  margin: "20px",
                  flexWrap: "wrap",
                }}
              >
                <CardContent>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {getTranslation(
                      "Last Month Registrations",
                      "Last Month Registrations",
                      "Last Month Registrations"
                    )}
                  </Typography>
                  <Typography variant="h4" style={{ textAlign: "center" }}>
                    {lastMonthData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xm={12} md={4} xl={4}>
              <SearchBar
                onRequestSearch={(searchString) => {
                  setSearchString(searchString);
                }}
                searchIcon={
                  <SearchIcon
                    style={{ fontSize: "32px", marginRight: "20px" }}
                  />
                }
                className={classes.searchBar}
              />
            </Grid>
            <Grid item xm={12} md={4} xl={4} style={{ marginTop: "4px" }}>
              <Autocomplete
                value={selectedApp}
                options={apps}
                size="small"
                onChange={(e, app) => {
                  setSelectedApp(app);
                }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // error={touched.app && Boolean(errors.app)}
                    label={getTranslation(
                      "Select App",
                      "Select App",
                      "Select App"
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <ToggleButtonGroup
            size="small"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
            id="tenantStatus"
            value={packageStatus}
            exclusive
            onChange={(event, status) => {
              if (status !== null) {
                setPackageStatus(status);
              }
            }}
          >
            <ToggleButton value={"all"} aria-label="left aligned">
              <Typography>{getTranslation("All", "All", "All")}</Typography>
            </ToggleButton>
            <ToggleButton value={"active"} aria-label="centered">
              <Typography>
                {getTranslation("Active", "Active", "Active")}
              </Typography>
            </ToggleButton>
            <ToggleButton value={"inactive"} aria-label="centered">
              <Typography>
                {getTranslation("In Active", "In Active", "In Active")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>

          <Table
            // title={getTranslation("Customers", "Les clients", "Kunden")}
            loading={isLoading}
            colums={columns}
            count={customers?.totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={(event, newPage) =>
              handleChangePage(event, newPage)
            }
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            rows={customers?.data}
            viewCreate
            onCreate={onCreate}
          />
        </CardContent>
      </CardComponent>
    </div>
  );
};

export default Customers;
