import { useEffect } from "react";
import { useState } from "react";
import { Get } from "../../actions"
import { Get_AllCustomersList_URL, Get_AppsList_URL } from "../../constants/apiUrls"
import { AddOrganizations, ListCustomerOrganizations } from "./components"

export const ManageOrganizations=()=>{
    const [customers, setCustomers] = useState([]);
    const [apps, setApps] = useState([]);

    useEffect(() => {
        Get(
            {},
            Get_AllCustomersList_URL,
            null,
            resp => {
                setCustomers(resp.data)
            },
            error => {

            }
        )
    }, [])

    
    useEffect(() => {
        Get(
            {},
            Get_AppsList_URL,
            null,
            resp => {
                setApps(resp.data)
            },
            error => {

            }
        )
    }, [])
    return <>
     <AddOrganizations customers={customers} apps={apps}/>
     <br/>
     <ListCustomerOrganizations customers={customers} apps={apps}/>
     </>
}