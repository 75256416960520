import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Get, GetAllMarketPackages, Post } from "../../../actions";
import { Get_CustomerAppByCustomerId_URL, Post_Upgrade_URL } from "../../../constants/apiUrls";
import { ViewPackageCard } from "./components/viewPackageCard";

export const Upgrade=(props)=>{

    const {customerId,appId}=useParams();
  const packages = useSelector((state) => state.packages.package);
  const [purchaseSubmiting, setPurchaseSubmiting] = useState(false);
  const [customerApp, setCustomerApp] = useState();
  const dispatch=useDispatch();
  const { enqueueSnackbar } = useSnackbar();
 const history=useHistory();
    useEffect(() => {
        dispatch(
        GetAllMarketPackages({
            appId: appId,
          }))
        Get({
            customerId: customerId
        },
            Get_CustomerAppByCustomerId_URL,
            null,
            resp => {
                setCustomerApp(resp.data)
            },
            error => {

            }
        )
    }, [])

    const handlePurchaseSubmission = (packageItem, paymentType) => {
        setPurchaseSubmiting(true)
        Post(
            {
                customerAppId: customerApp.id,
                packageId: packageItem.id,
                packagePaymentType: paymentType
            },
            Post_Upgrade_URL,
            null,
            (resp) => {
                setPurchaseSubmiting(false)
                history.push(`/checkout/${customerApp.id}`);
                enqueueSnackbar(resp.data,{variant:'success'})
            },
            (error) => {

                setPurchaseSubmiting(false)
                enqueueSnackbar(error.data,{variant:'error'})
            }
        );
    };
    return (<Grid container spacing={2}>

        {packages?.filter(x=>x.monthlyPrice>0 || x.yearlyPrice>0 || x.lifetimePrice>0).map((item) => {
            return (
                <Grid item sm={4} md={4} lg={4}>
                    <ViewPackageCard
                        isUpgrade
                        isBusy={purchaseSubmiting}
                        packageItem={item}
                        onSubmitPayment={handlePurchaseSubmission}
                        customerApp={customerApp}
                    />
                </Grid>
            );
        })}
    </Grid>
    )
}