import React from "react";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingButton from "../../../components/ButtonWithLoading";
import { UpdateCustomerProfile } from "../../../actions";
import { useDispatch, connect } from "react-redux";
import checkErrors from "../../../utils/responseUtils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";

const UpdateProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box maxWidth="100%">
      <Paper>
        <Box p={2}>
          <Typography variant="h6">
            {getTranslation(
              "Update Profile",
              "Mettre à jour le profil",
              "Profil aktualisieren"
            )}
          </Typography>
          <Box mt={1}>
            <Formik
              initialValues={props.user}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                dispatch(UpdateCustomerProfile(values, history)).then((res) => {
                  const errors = checkErrors(res);
                  if (!errors) {
                    enqueueSnackbar("Profile updated", {
                      variant: "success",
                    });

                    setSubmitting(false);
                  } else {
                    errors?.map((error) =>
                      enqueueSnackbar(error.message, {
                        variant: "error",
                      })
                    );
                    setSubmitting(false);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="firstName"
                        id={values.firstName}
                        label={getTranslation(
                          "First Name",
                          "Prénom",
                          "Vorname"
                        )}
                        value={values.firstName}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label={getTranslation("Last Name", "Nom", "Nachname")}
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <LoadingButton
                        color="primary"
                        label={getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )}
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(UpdateProfile);
