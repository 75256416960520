import React from 'react'
import UpdateEmail from "./UpdateEmail";
import UpdatePassword from "../customerViews/profile/UpdatePassword"
import {Grid} from "@material-ui/core"
const ProfileManagement = () => {
  return (
    <Grid container spacing={3}>
    <Grid item lg={12} md={12} sm={12} >
    <UpdateEmail />
    </Grid>
    <Grid item lg={12} md={12} sm={12} >
      <UpdatePassword />
    </Grid>
    </Grid>
  )
}


export default ProfileManagement
