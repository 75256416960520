import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { Get, GetAllMarketPackages, GetAllPackags, getPackageById, Post } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Box,
  Button,
  Container,
  CardHeader,
  Divider,
  TextField,
} from "@material-ui/core";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { ImFileEmpty } from "react-icons/im";
import { TrialPeriodDialog } from "./TrialPeriodDialog";
import { GetPackageEndPoint, Get_AppsList_URL, Post_Install_URL, Post_InternalAppLogin_URL } from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { ViewPackageCard } from "./components/viewPackageCard";
import styles from "../../../assests/styles/views/customerView/market/viewPackage";
import discountImage from '../../../assests/images/discount image.png'
import { MultiOrganizationRequest } from "./components";
import { getTranslation } from "../../../heplers/translationHelper";
import authService from "../../../utils/authUtils";


const getLoginURL = (id) => {
  if (id === 1) {
    return process.env.REACT_APP_CLIC_RENT_URL;
  } else if (id === 2) {
    return process.env.REACT_APP_MY_SERVICE_CLUB_URL;
  }
  else if (id === 4) {
    return process.env.REACT_APP_RESTO_CLICK_URL;
  }
};
const ViewAppPackages = (props) => {
  const classes = styles();
  const [openTrialPeriodDialog, setOpenTrialPeriodDialog] = useState(false);
  const [packageId, setPackageId] = useState(0);
  const [trialDays, setTrialDays] = useState(0);
  const [packageType, setPackageType] = useState(0)
  const [packageItem, setPackageItem] = useState(null)
  const [paymentData, setPaymentData] = useState({
    amount: 0,
    appId: 0,
    //packageId: 0,
  });
  const [purchaseSubmiting, setPurchaseSubmiting] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const packages = useSelector((state) => state.packages.package);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if(window.location.search){
      localStorage.setItem("visitorId", window.location.search.split("=")[1]);
    }

    if (params.packageId) {
      Get(
        { packageId: params.packageId },
        GetPackageEndPoint,
        null,
        resp => {
          console.log("kjgfdjgfdgf", resp)
          setPackageItem(resp?.data)
          setPaymentData({
            ...paymentData,
            appId: parseInt(params.appId),
          });
        },
        error => {

          enqueueSnackbar("Error Loading Package", { variant: "error" })
        }

      )
      dispatch(getPackageById(parseInt(params.packageId)));
    } else if (params.appId) {
      dispatch(
        GetAllMarketPackages({
          appId: params.appId,
        })
      );
      setPaymentData({
        ...paymentData,
        appId: parseInt(params.appId),
      });
    }

  }, [params]);

  const handleLoginInternalApp = (customerId) => {
    Post(
      { customerId: customerId },
      Post_InternalAppLogin_URL,
      null,
      (resp) => {
        console.log("asdasdasd", resp);
        window.open(getLoginURL(parseInt(params.appId)) + `${resp.data}`, "_blank");
      },
      (error) => { }
    );
  };

  const handlePurchaseSubmission = (selectedPackage, values) => {

    Post(
      values,
      Post_Install_URL,
      null,
      (resp) => {
        setOpenTrialPeriodDialog(false);
        if (selectedPackage.lifeTimePrice == 0 && selectedPackage.monthlyPrice == 0 && selectedPackage.yearlyPrice == 0) {
          console.log("ajkldh", resp)
          handleLoginInternalApp(resp.data?.customerId)
          props.history.push(`/myapps`);
        }
        else if (selectedPackage.trialDays > 0) {
          enqueueSnackbar("Trial Period has started", { variant: "success" });
          props.history.push(`/myapps`);
        } else {
          props.history.push(`/checkout/${resp?.data?.id}`);
          setPackageId(resp.id);
        }
        setPurchaseSubmiting(false)
      },
      (error) => {
        setOpenTrialPeriodDialog(false);
        enqueueSnackbar(error?.data, {
          variant: "error",
        });
        setPurchaseSubmiting(false)
        props.history.push(`/myapps`);
      }
    );
  };
  const handleOpenPaymentDialoge = (item, packageType) => {
    setPurchaseSubmiting(true)
    const initialValues = {
      appId: parseInt(params.appId),
      packagePaymentType: packageType,
      packageId: item.id,
    };
    // setPackageType(packageType)
    //  setPackageId(item.id);
    // setTrialDays(item.trialDays);
    // setOpenTrialPeriodDialog(true);
    handlePurchaseSubmission(item, initialValues)
  };

  return (
    <Grid container spacing={3}>
      {packageItem ? <Grid item sm={4} md={4} lg={4}>
        <ViewPackageCard
          packageItem={packageItem}
          onSubmitPayment={handleOpenPaymentDialoge}
        />
      </Grid> :
        <>
          <Grid item md={12} sm={12} lg={12}>
            <MultiOrganizationRequest />

          </Grid>
          {packages?.length > 0 ? (
            packages?.map((item) => {
              return (
                <Grid item sm={4} md={4} lg={4}>
                  <ViewPackageCard
                    isBusy={purchaseSubmiting}
                    packageItem={packageItem ? packageItem : item}
                    onSubmitPayment={handleOpenPaymentDialoge}
                  />
                </Grid>
              );
            })
          ) : (
            <Container className={classes.container} maxWidth="xs">
              <Box display="flex" alignItems="center" flexDirection="column">
                <ImFileEmpty className={classes.icon} />
                <Typography className={classes.text}>
                  {getTranslation("No packages available of this app","No packages available of this app","No packages available of this app")}
                </Typography>
              </Box>
            </Container>
          )}
        </>}
    </Grid>
  );
};

export default withRouter(ViewAppPackages);


