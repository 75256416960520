import React from 'react'
import { useSelector,connect } from 'react-redux'
import { Redirect,useLocation } from 'react-router-dom';
import authService from "../utils/authUtils";

const AuthGuard = (props) => {
  const location = useLocation()
  const {
    auth: { user },
  } = useSelector((state) => state);
    if (user === null && !authService.isAuthenticated()) {
      localStorage.setItem("redirectUrl",location.pathname)
      return <Redirect to="/login" />;
    }
   

 
    return props.children;
  

};
const mapStateToProps=(state)=>{
  return {
    user:state.auth.user
  }
}


export default connect(mapStateToProps,{})(AuthGuard);
