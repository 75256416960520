import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  injectStripe,
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  StripeProvider,
} from "react-stripe-elements";
import { getTranslation } from "../../../heplers/translationHelper";
import securelogo from "../../../assests/images/Poweredbystripe.png";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { STRIPE_API_KEY } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { MakeSmsPayment, Post } from "../../../actions";
import { useHistory } from "react-router-dom";
import { Post_ConfirmSMSPayment_URL } from "../../../constants/apiUrls";
import DialogComponent from "../../../components/Dialog";
import { SET_USER } from "../../../actions/types";

const CustomerCreditPage = ({ installerModel, ...rest }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [makeAutoSmsCreditEnabled, setMakeAutoSmsCreditEnabled] =
    useState(true);
  console.log("asddasdasd", makeAutoSmsCreditEnabled);
  const [amount, setAmount] = useState();
  const props = rest;
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const cardElementRef = props.elements.getElement("cardNumber");
    try {
      let paymentMethod = await props.stripe.createPaymentMethod({
        type: "card",
        card: cardElementRef,
      });
      let client_secret = "";
      if (paymentMethod) {
        dispatch(
          MakeSmsPayment(
            {
              amount: amount,
              paymentToken: paymentMethod?.paymentMethod?.id,
              makeAutoSmsCreditEnabled: makeAutoSmsCreditEnabled,
            },
            history
          )
        ).then(async (res) => {
          if (res.data.status == "3ds") {
            const confirmPayment = await props.stripe.confirmCardPayment(
              res.data?.data?.client_secret,
              { payment_method: { card: cardElementRef } }
            );
            if (confirmPayment.paymentIntent.status == "succeeded") {
              await Post(
                {
                  amount: amount,
                  intentId: confirmPayment.paymentIntent.id,
                  paymentToken: paymentMethod?.paymentMethod?.id,
                },
                Post_ConfirmSMSPayment_URL,
                null,
                (status) => {
                  enqueueSnackbar(status.data, { variant: "success" });
                  // history.push("/myapps");
                  setSubmitting(false);
                },
                (error) => {
                  enqueueSnackbar(error.data, { variant: "error" });
                  setSubmitting(false);
                }
              );
            } else {
              enqueueSnackbar(
                "We were not able to make the payments using this card, Please try another card or contact your bank for more details about the issue.",
                { variant: "error" }
              );
              // history.push("/myapps");
              setSubmitting(false);
            }
          } else {
            enqueueSnackbar("Payment succeeded", {
              variant: res.data.status == "error" ? "error" : "success",
            });
            dispatch({
              type: SET_USER,
              payload: {
                ...userData,
                smsCredit: parseInt(userData?.smsCredit) + parseInt(amount),
              },
            });
            setAmount(0);
            setSubmitting(false);
          }
        });
      }
    } catch (error) {
      enqueueSnackbar("Invalid card information", { variant: "error" });
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card style={{ width: "100%" }}>
        <CardHeader
          title={getTranslation("Payment Info", "Payment Info", "Payment Info")}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={3}>
              <TextField
                required
                fullWidth
                id='amount'
                size='small'
                name='amount'
                InputProps={{ disableUnderline: true }}
                variant='outlined'
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                label={getTranslation(
                  "Enter amount",
                  "Enter amount",
                  "Enter amount"
                )}
              />
            </Grid>
            <table>
              <tr>
                <td>
                  <Checkbox
                    checked={makeAutoSmsCreditEnabled}
                    onChange={() => {
                      setMakeAutoSmsCreditEnabled(!makeAutoSmsCreditEnabled);
                    }}
                  />
                </td>
                <td>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Enable Auto Credit",
                      "Enable Auto Credit",
                      "Enable Auto Credit"
                    )}
                  </Typography>
                </td>
              </tr>
            </table>
            {amount ? (
              <>
                <Grid item sm={12} md={12} lg={12}>
                  <Box
                    width='100%'
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Typography variant='body1'>
                      {getTranslation(
                        "Pay Via",
                        "Payer via",
                        "Zahlen Sie über"
                      )}
                    </Typography>
                    <Box>
                      <img
                        src={securelogo}
                        width='150'
                        height='30'
                        alt='visa card and mastercard logo png'
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={12} md={12} lg={5}>
                  <Typography color='textPrimary'>
                    {getTranslation(
                      "Card Number",
                      "Numéro de carte",
                      "Kartennummer"
                    )}
                  </Typography>
                  <Box
                    mt={1}
                    border={`1px solid gray`}
                    p={1.3}
                    borderRadius='10px'
                  >
                    <CardNumberElement />
                  </Box>
                </Grid>
                <Grid item sm={12} md={6} lg={3}>
                  <Typography color='textPrimary'>
                    {getTranslation(
                      "Expiry Date",
                      "Date d'expiration",
                      "Verfallsdatum"
                    )}
                  </Typography>

                  <Box
                    border={`1px solid gray`}
                    p={1}
                    mt={1}
                    borderRadius='10px'
                  >
                    <CardExpiryElement />
                  </Box>
                </Grid>
                <Grid item sm={12} md={6} lg={3}>
                  <Typography color='textPrimary'>
                    {getTranslation("CVC code", "CVC code", "CVC code")}
                  </Typography>
                  <Box
                    mb={2}
                    border={`1px solid gray`}
                    mt={1}
                    p={1}
                    borderRadius='10px'
                  >
                    <CardCVCElement />
                  </Box>
                </Grid>
              </>
            ) : (
              <div />
            )}
          </Grid>
          <Box display='flex' alignItems='flex-end' justifyContent='flex-end'>
            <ButtonWithLoading
              color='primary'
              label={
                amount
                  ? "Pay" + amount + " €"
                  : getTranslation("Pay", "Pay", "Pay")
              }
              loading={isSubmitting}
              type='submit'
              variant='contained'
              size='small'
            />
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};

const InjectedCustomerCreditPage = injectStripe(CustomerCreditPage);

const WrappedCustomerCreditPage = ({ installerModel, ...rest }) => {
  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <Elements>
        <InjectedCustomerCreditPage />
      </Elements>
    </StripeProvider>
  );
};

export default WrappedCustomerCreditPage;
