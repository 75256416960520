import React, { useEffect, useState } from "react";
import { Grid, Paper, Button, Box } from "@material-ui/core";
import FilterPackages from "./FilterPackages"
import Packages from "./Packages";
import Dialoge from "../../components/Dialog";
import AddNewPackage from "./AddNewPackage";
import { useDispatch } from "react-redux";
import { GetAppById, getPackageById, PackageEdit } from "../../actions";
import {useSelector} from "react-redux"
import { useParams } from "react-router-dom";

const ManagePackages = () => {
  const [open, setOpen] = useState(false);
const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const packageData = useSelector(state=>state.packages.editPackage)
  const {appId}=useParams()
  const {app} = useSelector(state=>state.app)
  useEffect(()=>{
    dispatch(GetAppById(appId,null))
  },[appId]);

  const handleClick = () => {
    setOpen(true);
  };
  
    return (
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12} md={12}>
          <Packages open={() => setOpen(true)} />
        </Grid>
        <Dialoge
          title={packageData ? "Edit package" : "Add new Package"}
          fullWidth
          maxWidth="md"
          open={open}
          onClose = {() => {
            setOpen(false);
            dispatch(PackageEdit(null));
          }}
        >
          <AddNewPackage editApp={app} onClose={() => setOpen(false)} />
        </Dialoge>
      </Grid>
    );
}

export default ManagePackages
