import React, { useState } from "react";
import { Grid, Paper, Button, Box } from "@material-ui/core";
import FilterCustomers from "./FilterCustomers";
import Customers from "./Customers";
import Dialoge from "../../components/Dialog";
import AddNewCustomer from "./AddNewCustomer";

const ManageCustomers = () => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} lg={12} md={12}>
        <Customers onCreate={handleClick} />
      </Grid>
      <Dialoge
        title="Add new Customer"
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
      >
        <AddNewCustomer onClose={() => setOpen(false)} />
      </Dialoge>
    </Grid>
  );
};

export default ManageCustomers;
