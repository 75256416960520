
export const SET_USER = "SET_USER";
export const SET_USER_BY_ID = "SET_USER_BY_ID";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOADING = "IS_USERS_LOADING";
export const USER_LOADED = "IS_USERS_LOADED";
export const USER_ERROR = "IS_USERS_ERROR";

export const OPEN_NEW_APP_DIALOGE = "OPEN_NEW_APP_DIALOGE";
export const CLOSE_NEW_APP_DIALOGE = "CLOSE_NEW_APP_DIALOGE";



export const LOADING_APPS = "LOADING_APPS";
export const APP_LOADED = "APP_LOADED";
export const APPS_DATA = "APPS_DATA";
export const APPS_ERRORS = "APPS_ERRORS";
export const APPS_EDIT = "APPS_EDIT";
export const APP_DATA = "APP_DATA";
export const MY_APP_DATA = "MY_APP_DATA";
// export const APP_DATA_ERROR = "APP_DATA_ERROR";


export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const CUSTOMERS_DATA = "CUSTOMERS_DATA";
export const CUSTOMERS_ERRORS = "CUSTOMERS_ERRORS";

export const PACKAGES_DATA = "PACKAGES_DATA";
export const PACKAGE_DATA = "PACKAGE_DATA";
export const PACKAGES_LOADING = "PACKAGES_LOADING";
export const PACKAGES_ERROR = "PACKAGES_ERROR";
export const PACKAGES_EDIT = "PACKAGES_EDIT";

export const EMAIL_SETTINGS = "EMAIL_SETTINGS";
export const LOAD_EMAIL_SETTINGS = "LOAD_EMAIL_SETTINGS";
export const EMAIL_SETTINGS_ERROR = "EMAIL_SETTINGS_ERROR";