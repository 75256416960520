import { Card, CardContent, Grid, TextField, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { useEffect, useState } from "react"
import { Get } from "../../../actions"
import CardComponent from "../../../components/Card"
import TableComponent from "../../../components/table"
import { Get_AllCustomerApps_URL, Get_AllCustomersList_URL } from "../../../constants/apiUrls"
import { getTranslation } from "../../../heplers/translationHelper"

export const ListCustomerOrganizations = ({ customers,apps }) => {
    const [selectedCustomer, setSelectedCustomer] = useState()
    const [selectedApp, setSelectedApp] = useState()
    const [customerApps, setCustomerApps] = useState([])

    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        Get(
            { customerId: selectedCustomer?.id },
            Get_AllCustomerApps_URL,
            null,
            resp => {
                setCustomerApps(resp.data)
            },
            error => {

            }
        )
    }, [selectedCustomer])


    const columns = [
        {
            id: "organizationName",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Organization Name", "Organization Name", "Organization Name"),
        },
        {
            id: "customerId",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Customer Id", "Customer Id", "Customer Id"),
        },
        {
            id: "appTitle",
            numeric: false,
            disablePadding: true,
            label: getTranslation("App Title", "App Title", "App Title"),
        },
        {
            id: "packageName",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Package Name", "Package Name", "Package Name"),
        },
        {
            id: "packagePaymentType",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Package Deal", "Package Deal", "Package Deal"),
            component: ({row}) => row.packagePaymentType==0 ? <Typography>Monthly</Typography> :  row.packagePaymentType==1 ? <Typography>Yearly</Typography>:<Typography>Life Time Deal</Typography>
        },
        {
            id: "isPaid",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Is Paid", "Is Paid", "Is Paid"),
            component: ({row}) => row.isPaid ? <Typography>Paid</Typography> : <Typography>Not Paid</Typography>
        },
        {
            id: "isExpired",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Is Expired", "Is Expired", "Is Expired"),
            component: ({row}) => row.isExpired==false ? row.isTrialExpired == false ? <Typography>On Trial</Typography>: <Typography>Active</Typography> : <Typography>Expired</Typography>
        },
        {
            id: "isStarter",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Is Starter", "Is Starter", "Is Starter"),
            component: ({row}) => row.isStarter ? <Typography>Yes</Typography> : <Typography>No</Typography>
        },
        {
            id: "packageExpiry",
            numeric: false,
            disablePadding: true,
            label: getTranslation("Package Expiry", "Package Expiry", "Package Expiry"),
            format: 'date'
        },
    ];

    const filterByApp=()=>{
        if(selectedApp)
            return customerApps.filter(x=>x.appId==selectedApp?.id)
     return customerApps;
    }

    return <CardComponent title="Customer Apps">
        <CardContent>
            <Grid container spacing={2}>
                <Grid item sx={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                        value={selectedCustomer}
                        options={customers}
                        size="small"
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedCustomer(value)
                            }
                        }}
                        getOptionLabel={(option) => option ? option.name + " | " + option.email : ""}
                        //   style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                // error={touched.customerId && Boolean(errors.customerId)}
                                label={getTranslation("Select Customer", "Select Customer", "Select Customer")}
                            />
                        )}
                    />
                </Grid>
                <Grid item sx={12} sm={12} md={4} lg={4}>
                    <Autocomplete
                        value={selectedApp}
                        options={apps}
                        size="small"
                        onChange={(e, app) => {
                            setSelectedApp(app)

                        }}
                        getOptionLabel={(option) => option.title}
                        //   style={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                // error={touched.app && Boolean(errors.app)}
                                label={getTranslation("Select App", "Select App", "Select App")}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <br/>
            <br/>
            <TableComponent
                auto
                dense
                // title={getTranslation("Customer Apps", "Customer Apps", "Customer Apps")}
                colums={columns}
                count={filterByApp(customerApps)?.length}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
                handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                rows={filterByApp(customerApps)}
                sortBy="organizationName"
                viewSearch
            />
        </CardContent>

    </CardComponent>
}