export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: "-0.24px",
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: "-0.05px",
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: "-0.05px",
  },
  subtitle2: {
    textAlign: "left",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#000000",
    opacity: "60%",
    lineHeight: 2,
  },

  overline: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#000000",
    lineHeight: 2,
    marginLeft: "5px",
    marginRight: "5px",
  },
  body1: {
    color: "#000000",
  },
};
