import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core";
import DialogComponent from "../../../components/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../src/heplers/translationHelper";
export const AddNewTextDialog = ({
  editTranslation,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const initialValue = {
    text: "",
    english: "",
    french: "",
    german: "",
  };
  const basicValidationSchema = Yup.object().shape({
    text: Yup.string().required("This field is required"),
    english: Yup.string().required("This field is required"),
  });
  const defaultValue = editTranslation ? editTranslation : initialValue;
  return (
    <DialogComponent
      title={getTranslation(
        "Add New Word",
        "Ajouter un nouveau mot",
        "Neues Wort hinzuf�gen"
      )}
      open={isOpen}
      onClose={onClose}
    >
      <Formik
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm();
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="text"
                      label={getTranslation(
                        "New Word",
                        "Nouveau mot",
                        "Neues Wort"
                      )}
                      size="small"
                      variant="outlined"
                      required
                      name="text"
                      {...getFieldProps("text")}
                      error={touched.text && Boolean(errors.text)}
                      helperText={touched.text && errors.text}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="english"
                      label={getTranslation("English", "Anglais", "Englisch")}
                      size="small"
                      variant="outlined"
                      required
                      name="english"
                      {...getFieldProps("english")}
                      error={touched.english && Boolean(errors.english)}
                      helperText={touched.english && errors.english}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="french"
                      label={getTranslation(
                        "French",
                        "Fran�ais",
                        "Franz�sisch"
                      )}
                      size="small"
                      variant="outlined"
                      required
                      name="french"
                      {...getFieldProps("french")}
                      error={touched.french && Boolean(errors.french)}
                      helperText={touched.french && errors.french}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="german"
                      label={getTranslation("German", "Allemand", "Deutsch")}
                      size="small"
                      variant="outlined"
                      required
                      name="german"
                      {...getFieldProps("german")}
                      error={touched.german && Boolean(errors.german)}
                      helperText={touched.german && errors.german}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box
                  pr={1}
                  pt={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonWithLoading
                    label={editTranslation ? "Update" : "Add"}
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </DialogComponent>
  );
};
