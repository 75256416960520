import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DialogActions from "../../components/DialogActions";
import DialogContent from "../../components/DialogContent";
import LoadingButton from "../../components/ButtonWithLoading";
import KeysManagement from "./ManageKeys";
import UploadImage from "./UploadImage";
import { useDispatch, useSelector } from "react-redux";
import {
  IncertApp,
  UpdateApp,
  ApplicationEdit,
  GetAllApplications,
} from "../../actions";
import checkErrors from "../../utils/responseUtils";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { getTranslation } from "../../heplers/translationHelper";

const AddNewApp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [imageIcon, setImageIcon] = useState("");
  const [imageLogo, setImageLogo] = useState("");
  const [id, setId] = useState(0);
  const [keys, setKeys] = useState([]);

  const initialVales = {
    title: "",
    prefix: "",
    description: "",
    keys: "",
    installationUrl: "",
    enableManualDBCretion: true,
  };

  const editApp = useSelector((state) => state.app.edit);

  useEffect(() => {
    if (editApp) {
      setId(editApp.id);
      setImageIcon(editApp.icon);
      setImageLogo(editApp.logo);
      setKeys(editApp.appKeys);
    }
  }, [editApp]);

  return (
    <Formik
      enableReinitialize
      initialValues={editApp ? editApp : initialVales}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Required"),
        prefix: Yup.string().required("Required"),
        description: Yup.string().max(150),
        installationUrl: Yup.string().required("Required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          editApp
            ? UpdateApp(
                {
                  ...values,
                  id,
                  icon: imageIcon,
                  logo: imageLogo,
                  appKeys: keys,
                },
                history
              )
            : IncertApp(
                {
                  ...values,
                  id,
                  icon: imageIcon,
                  logo: imageLogo,
                  appKeys: keys,
                },
                history
              )
        ).then((res) => {
          const errors = checkErrors(res);
          if (!errors) {
            enqueueSnackbar("App Added", { variant: "success" });
            setSubmitting(false);
            props.onClose(false);
            dispatch(
              GetAllApplications({ pageNumber: 0, pageSize: 10 }, history)
            );
            return editApp ? dispatch(ApplicationEdit(null)) : null;
          } else {
            errors?.map((error) =>
              enqueueSnackbar(error.message, { variant: "error" })
            );
            setSubmitting(false);
          }
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
      }) => (
        <Form>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={12}>
                <UploadImage image={setImageIcon} title="Upload Icon" />
                <br />
                <UploadImage image={setImageLogo} title="Upload Logo" />{" "}
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation("Title", "Titre", "Titel")}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  error={touched.title && Boolean(errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation("Prefix", "Pr�fixe", "Pr�fix")}
                  name="prefix"
                  value={values.prefix}
                  onChange={handleChange}
                  error={touched.prefix && Boolean(errors.prefix)}
                  helperText={touched.prefix && errors.prefix}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Description (max 150 characters)",
                    "Description (max 150 caract�res)",
                    "Beschreibung (max. 150 Zeichen)"
                  )}
                  multiline
                  rows={3}
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={(e) => {
                    setFieldTouched("description");
                    handleChange(e);
                  }}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e, isChecked) => {
                        setFieldValue("enableManualDBCretion", isChecked);
                      }}
                      checked={values.enableManualDBCretion}
                    />
                  }
                  label={getTranslation(
                    "Disable Auto Database Creation",
                    "D�sactiver la cr�ation automatique de base de donn�es",
                    "Deaktivieren Sie die automatische Datenbankerstellung"
                  )}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={getTranslation(
                    "Installation URL",
                    "URL d'installation",
                    "Installations-URL"
                  )}
                  name="installationUrl"
                  value={values.installationUrl}
                  onChange={handleChange}
                  error={
                    touched.installationUrl && Boolean(errors.installationUrl)
                  }
                  helperText={touched.installationUrl && errors.installationUrl}
                />
              </Grid>
              <Grid item sm={12} md={12} lg={12}>
                <Typography variant="body1" color="initial">
                  {getTranslation("Criteria", "Crit�re", "Kriterien")}
                </Typography>
                <Box my={2}>
                  {" "}
                  <KeysManagement keys={keys} setKeys={setKeys} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              color="primary"
              label={editApp ? "Edit" : "Add"}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default AddNewApp;
