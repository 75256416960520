import React from "react";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingButton from "../../components/ButtonWithLoading";
import { ChangeAdminEmail, getUserData } from "../../actions";
import { useDispatch, connect } from "react-redux";
import checkErrors from "../../utils/responseUtils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";

const UpdateProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box maxWidth="100%">
      <Paper>
        <Box p={2}>
          <Typography variant="h6">
            {getTranslation(
              "Update Email",
              "Mettre à jour l'e-mail",
              "E-Mail aktualisieren"
            )}
          </Typography>
          <Box mt={1}>
            <Formik
              enableReinitialize={true}
              initialValues={{ email: props.user?.email }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Enter correct email")
                  .required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                dispatch(ChangeAdminEmail(values, history)).then((res) => {
                  const errors = checkErrors(res);
                  if (!errors) {
                    enqueueSnackbar("Email Updated", {
                      variant: "success",
                    });
                    dispatch(getUserData(history));

                    setSubmitting(false);
                  } else {
                    errors?.map((error) =>
                      enqueueSnackbar(error.message, {
                        variant: "error",
                      })
                    );
                    setSubmitting(false);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="email"
                        id="dkfjkdjfkd"
                        autoFocus
                        label={getTranslation("Email", "email", "E-Mail")}
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} lg={4}>
                      <LoadingButton
                        color="primary"
                        label={getTranslation(
                          "Update",
                          "Mettre à jour",
                          "Aktualisieren"
                        )}
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, {})(UpdateProfile);
