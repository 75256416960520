import axios from "../helpers/apiHelper";
import {
  LOADING_APPS,
  APPS_EDIT,
  APPS_DATA,
  APPS_ERRORS,
  APP_DATA,
} from "./types";

const IncertAppEndPoint = "Apps/InsertApp";
const UpdateAppEndPoint = "Apps/UpdateApp";
const GetAllAppsEndPoint = "Apps/GetApps";
const GetAppByIdEndPoint = "Apps/GetApp";


//Insert Application Action
export const IncertApp = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(IncertAppEndPoint, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

//Update Application Actions
export const UpdateApp = (payload, history) => async (dispatch) => {
  try {
    const response = await axios(history).post(UpdateAppEndPoint, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};


//Get Application with pagination as well as the filter the apps 
export const GetAllApplications = (query,history) => async (dispatch) => {
  try {
      dispatch({ type: LOADING_APPS });
    const response = await axios(history).get(GetAllAppsEndPoint, {
      params: query,
    });
      dispatch({ type: APPS_DATA,payload:response.data });
  } catch (error) {
      dispatch({ type: APPS_ERRORS, payload: error.response });
      
  }
};

// This action call when we click the certain row for edit app .
// this action open the dialouge and populate the data of app into fields,
//After completion of updation this should dispatch to null for clearing and closing the dialouge 
export const ApplicationEdit = (row) => async (dispatch) => {
    dispatch({ type: APPS_EDIT, payload: row });
};

//Get single App data from their ID
export const GetAppById = (id,history)=>async dispatch=>{
  try {
    const response = await axios(history).get(GetAppByIdEndPoint,{params:{id}});
    dispatch({ type: APP_DATA, payload: response.data });
    
  } catch (error) {
     dispatch({ type: APPS_ERRORS, payload: error.response });
  }
}