import React, { useEffect, useState } from "react";
import { getTranslation } from "../../heplers/translationHelper";
import TableComponent from "../../components/table";
import { Get } from "../../actions";
import {
  Get_AllUserActivitiesByVisitorIdWithPagination_URL,
  Get_AllUsersActivitiesWithPagination_URL,
} from "../../constants/apiUrls";
import { Typography } from "@material-ui/core";
import { format } from "../../heplers/format";

const columns = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Type", "Type", "Type"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>
            {row?.type == 0
              ? "Registration"
              : row?.type == 1
              ? "Login"
              : row?.type == 2
              ? "SystemAcvity"
              : row?.type == 3
              ? "General"
              : "Page"}
          </Typography>
        </div>
      );
    },
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Status", "Status", "Status"),
  },
  {
    id: "activityAt",
    numeric: false,
    disablePadding: false,
    label: getTranslation("Activity", "Activity", "Activity"),
    component: ({ row }) => {
      return (
        <div>
          <Typography>{format("dateTime", row?.activityAt)}</Typography>
        </div>
      );
    },
  },
];

const CustomerSummaryDialog = ({ visitorId }) => {
  const [visitorData, setVisitorData] = useState([]);
  const [page, setPage] = useState(0);
  let pageSize = localStorage.getItem("sizeCustomerLogsSummary");
  if (pageSize == null) {
    localStorage.setItem("sizeCustomerLogsSummary", 10);
    pageSize = 10;
  }
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  useEffect(() => {
    Get(
      {
        searchString: "",
        visitorId: visitorId,
        sortDirection: 1,
        sort: "createdAt",
        pageNumber: page,
        pageSize: rowsPerPage,
      },
      Get_AllUserActivitiesByVisitorIdWithPagination_URL,
      null,
      (resp) => {
        setVisitorData(resp?.data);
      },
      (error) => {}
    );
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem(
      "sizeCustomerLogsSummary",
      parseInt(event.target.value)
    );
    setRowsPerPage(localStorage.getItem("sizeCustomerLogsSummary"));
    setPage(0);
  };

  return (
    <div>
      <TableComponent
        dense
        colums={columns}
        rows={visitorData?.data}
        count={visitorData?.totalCount}
        viewSearch
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
      />
    </div>
  );
};

export default CustomerSummaryDialog;
