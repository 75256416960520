import React,{useEffect} from 'react';
import AppCard from "./MarketAppCard";
import { Grid } from "@material-ui/core";
import {GetAllApplications} from "../../../actions";
import {useDispatch,useSelector} from "react-redux";
import {useHistory} from "react-router-dom"
import { MultiOrganizationRequest } from './components';


const MarketManagement = () => {
  const dispatch = useDispatch();
  const history= useHistory()
  const appsData= useSelector(state=>state.app.apps)

  useEffect(() => {
    dispatch(GetAllApplications({ pageNumber: 0, pageSize: 100 }, history));
  }, [])
    return (<>
    <MultiOrganizationRequest/>
      <Grid container spacing={3}>
        {appsData?.data?.map((app) => (
          <Grid item sm={4} md={3} lg={3}>
            <AppCard data={app} />
          </Grid>
        ))}
      </Grid></>
    );
}

export default MarketManagement
