import { useEffect, useState } from "react";
import { Get, Post } from "../../../../actions";
import {
  Get_AllPackagesOfApp_URL,
  Get_AppsList_URL,
  Post_SendEmail_URL,
} from "../../../../constants/apiUrls";
import * as Yup from "yup";

import DialogComponent from "../../../../components/Dialog";
import { Form, Formik } from "formik";
import { getTranslation } from "../../../../heplers/translationHelper";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";

export const MultiOrganizationRequest = ({ openDialog, appId }) => {
  const [apps, setApps] = useState();
  const [selectedApp, setSelectedApp] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.auth.user);
  const appReducer = useSelector((state) => state.app);
  const history = useHistory();

  const [open, setOpen] = useState(openDialog);
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);
  useEffect(() => {
    setSelectedApp(apps?.find((x) => x.id == appId));
    if (appId) {
      getPackages(appId);
    }
  }, [apps, appId]);
  console.log("jasjadhasjdad", apps, selectedApp, appId);

  useEffect(() => {
    Get(
      {},
      Get_AppsList_URL,
      null,
      (resp) => {
        setApps(resp.data);
      },
      (error) => {}
    );
  }, []);

  const onSubmit = (values, actions) => {
    let packagePaymentType = "";
    if (values.packagePaymentType == 0) packagePaymentType = "Monthly";
    else if (values.packagePaymentType == 1) packagePaymentType = "Yearly";
    else if (values.packagePaymentType == 2) packagePaymentType = "Life time";

    Post(
      {
        fromName: "App Manager",
        to: [
          {
            name: "App Manager",
            address: "asghar8298@gmail.com",
          },
        ],
        subject: "New Request For Multiple Organizations",
        message:
          `<p><strong>Customer Name</strong>: ${user.firstName} ${user.lastName}</p>` +
          `<p><strong>Customer Email</strong>: ${user.email}</p>` +
          `<p><strong>App Name</strong>: ${values.app?.title}</p>` +
          `<p><strong>Package Name</strong>: ${values.package?.title}</p>` +
          `<p><strong>Package Period</strong>: ${packagePaymentType}</p>` +
          `<p><strong>Quantity</strong>: ${values.quantity}</p>` +
          `<p><strong>Comments</strong>: ${values.comments}</p>`,
        isHtml: true,
      },
      Post_SendEmail_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        enqueueSnackbar("Your request has been sent to our sales department.", {
          variant: "success",
        });
        setOpen(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  };
  const [packages, setPackages] = useState([]);
  const [loadPackages, setLoadPackages] = useState(false);

  const getPackages = (appId) => {
    setLoadPackages(true);
    Get(
      { appId },
      Get_AllPackagesOfApp_URL,
      null,
      (resp) => {
        setPackages(resp.data);
        setLoadPackages(false);
      },
      (error) => {}
    );
  };

  const defaultValue = {
    appId: selectedApp ? selectedApp?.id : 0,
    app: selectedApp ? selectedApp : null,
    packageId: selectedApp ? packages[0]?.id : 0,
    package: selectedApp ? packages[0] : null,
    quantity: 1,
    comments: "",
    packagePaymentType: 0,
  };

  const basicValidationSchema = Yup.object().shape({
    appId: Yup.number().min(1),
    quantity: Yup.number().min(1).required(
      getTranslation(
        "Quantity must be greater than or equal to 1",
        "Quantity must be greater than or equal to 1",
        "Quantity must be greater than or equal to 1"
      )
    ),
    packageId: Yup.number().min(1),
  });

  return (
    <>
      {" "}
      <Box display="flex" justifyContent="center"></Box>
      <br />
      <br />
      <Alert
        severity="error"
        action={
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
          >
            {getTranslation(
              "Buy Multiple Organizations",
              "Buy Multiple Organizations",
              "Buy Multiple Organizations"
            )}
          </Button>
        }
      >
        <AlertTitle>
          {getTranslation(
            "Big discount upto 12% off",
            "Big discount upto 12% off",
            "Big discount upto 12% off"
          )}
        </AlertTitle>
        {getTranslation(
          "We can provide you discount on",
          "We can provide you discount on",
          "We can provide you discount on"
        )}{" "}
        <strong>
          {getTranslation(
            "more than 1 organizations",
            "more than 1 organizations",
            "more than 1 organizations"
          )}
        </strong>
        .
        <a href="#" onClick={() => setOpen(true)}>
          {getTranslation("Click here", "Click here", "Click here")}
        </a>{" "}
        {getTranslation(
          "to send a request",
          "to send a request",
          "to send a request"
        )}
      </Alert>
      <br />
      <DialogComponent
        title={getTranslation(
          "Request for multiple organizations",
          "Request for multiple organizations",
          "Request for multiple organizations"
        )}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          style={{ width: "500px", marginTop: "20px", marginBottom: "20px" }}
        >
          <Formik
            enableReinitialize
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              onSubmit(values, actions);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      disabled={appId ? true : false}
                      value={values.app}
                      options={apps}
                      size="small"
                      onChange={(e, app) => {
                        if (app) {
                          setFieldValue("app", app);
                          setFieldValue("appId", app.id);
                          getPackages(app.id);
                        }
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.appId && Boolean(errors.appId)}
                          label={getTranslation(
                            "Select App",
                            "Select App",
                            "Select App"
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={12} sm={12} md={6} lg={6}>
                    <Autocomplete
                      disabled={packages.length == 0}
                      value={values.package}
                      options={packages}
                      size="small"
                      onChange={(e, value) => {
                        if (value) {
                          setFieldValue("package", value);
                          setFieldValue("packageId", value.id);
                          if (value.isMonthly)
                            setFieldValue("packagePaymentType", 0);
                          else if (value.isYearly)
                            setFieldValue("packagePaymentType", 1);
                          else if (value.isLifeTime)
                            setFieldValue("packagePaymentType", 2);
                        }
                      }}
                      getOptionLabel={(option) => option.title}
                      //   style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={touched.packageId && Boolean(errors.packageId)}
                          label={getTranslation(
                            "Select Package",
                            "Select Package",
                            "Select Package"
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel id="demo-select-small">
                        Select Period
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-select-small"
                        value={values.packagePaymentType}
                        disabled={values.package == null}
                        label="Select Period"
                        onChange={(e) => {
                          setFieldValue("packagePaymentType", e.target.value);
                        }}
                      >
                        {values.package?.isMonthly ? (
                          <MenuItem value={0}>
                            {getTranslation("Monthly")} |{" "}
                            {values.package?.monthlyPrice} €
                          </MenuItem>
                        ) : null}
                        {values.package?.isYearly ? (
                          <MenuItem value={1}>
                            {getTranslation("Yearly")} |{" "}
                            {values.package?.yearlyPrice} €
                          </MenuItem>
                        ) : null}
                        {values.package?.isLifeTime ? (
                          <MenuItem value={2}>
                            {getTranslation("Life time")} |{" "}
                            {values.package?.lifeTimePrice} €
                          </MenuItem>
                        ) : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="quantity"
                      label={getTranslation("Quantity", "Quantity", "Quantity")}
                      required
                      size="small"
                      variant="outlined"
                      name="quantity"
                      type="number"
                      value={values?.quantity}
                      onChange={(e)=>{
                        setFieldValue("quantity",e.target.value);
                      }}
                      // {...getFieldProps("quantity")}
                      error={touched.quantity && Boolean(errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                      InputProps={{ inputProps: { min: 1 } }}
                  
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      rows={5}
                      multiline
                      fullWidth
                      id="comments"
                      label={getTranslation(
                        "Comments (optional)",
                        "Comments (optional)",
                        "Comments (optional)"
                      )}
                      size="small"
                      variant="outlined"
                      name="comments"
                      {...getFieldProps("comments")}
                      error={touched.comments && Boolean(errors.comments)}
                      helperText={touched.comments && errors.comments}
                      inputProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Box
                  mt={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px" }}
                    onClick={() => setOpen(false)}
                  >
                    {getTranslation("Cancel", "Cancel", "Cancel")}
                  </Button>
                  <ButtonWithLoading
                    label={getTranslation(
                      "Send Request",
                      "Send Request",
                      "Send Request"
                    )}
                    color="primary"
                    variant="outlined"
                    type="submit"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </DialogComponent>
    </>
  );
};
