import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Grid, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingButton from "../../../components/ButtonWithLoading";
import { ChangeCustomerPassword } from "../../../actions";
import { useDispatch } from "react-redux";

import checkErrors from "../../../utils/responseUtils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";

const UpdatePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box maxWidth="100%">
      <Paper>
        <Box p={2}>
          <Typography variant="h6">
            {getTranslation(
              "Update Password",
              "Mettre � jour le mot de passe",
              "Passwort erneuern"
            )}
          </Typography>
          <Box width="100%" mt={1}>
            <Formik
              enableReinitialize
              initialValues={{
                currentPassword: "",
                newPassword: "",
                newConfirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string("Required").required("Required"),
                newPassword: Yup.string()
                  .min(6, "Password atleast 6 character long")
                  .required("Password is required"),
                newConfirmPassword: Yup.string()
                  .min(6, "Password atleast 6 character long")
                  .oneOf(
                    [Yup.ref("newPassword"), null],
                    "Passwords must match"
                  ),
              })}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                console.log("i am running ", values);

                dispatch(ChangeCustomerPassword(values, history)).then(
                  (res) => {
                    const errors = checkErrors(res);
                    if (!errors) {
                      enqueueSnackbar("Password has been changed", {
                        variant: "success",
                      });
                      actions.setSubmitting(false);
                    } else {
                      errors?.map((error) =>
                        enqueueSnackbar(error.message, {
                          variant: "error",
                        })
                      );
                      actions.setSubmitting(false);
                    }
                  }
                );
                actions.resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                setSubmitting,
                getFieldProps,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={4}>
                      <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        size="small"
                        label={getTranslation(
                          "Old Password",
                          "ancien mot de passe",
                          "altes Kennwort"
                        )}
                        name="currentPassword"
                        //value={values.password}
                        onChange={handleChange}
                        error={
                          touched.currentPassword &&
                          Boolean(errors.currentPassword)
                        }
                        helperText={
                          touched.currentPassword && errors.currentPassword
                        }
                        {...getFieldProps("currentPassword")}
                      />
                    </Grid>

                    <Grid item sm={12} md={12} lg={4}>
                      <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        size="small"
                        label={getTranslation(
                          "New Password",
                          "nouveau mot de passe",
                          "Neues Kennwort"
                        )}
                        name="newPassword"
                        //value={values.password}
                        onChange={handleChange}
                        error={
                          touched.newPassword && Boolean(errors.newPassword)
                        }
                        helperText={touched.newPassword && errors.newPassword}
                        {...getFieldProps("newPassword")}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={4}>
                      <TextField
                        fullWidth
                        type="password"
                        variant="outlined"
                        size="small"
                        label={getTranslation(
                          "Confirm Password",
                          "confirmer mot de passe",
                          "Kennwort best�tigen"
                        )}
                        name="newConfirmPassword"
                        // value={values.confirmPassword}
                        onChange={handleChange}
                        error={
                          touched.newConfirmPassword &&
                          Boolean(errors.newConfirmPassword)
                        }
                        helperText={
                          errors.newConfirmPassword &&
                          touched.newConfirmPassword &&
                          errors.newConfirmPassword
                        }
                        {...getFieldProps("newConfirmPassword")}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <LoadingButton
                        color="primary"
                        label={getTranslation(
                          "Update Password",
                          "mettre � jour mot de passe",
                          "Kennwort aktualisieren"
                        )}
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        style={{ float: "right" }}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default UpdatePassword;
