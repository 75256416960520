import React from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FileDownloadIcon from "@material-ui/icons/CloudDownloadOutlined";

import RepeatIcon from "@material-ui/icons/ReplayOutlined";
import PrintIcon from "@material-ui/icons/Print";
import MessageIcon from "@material-ui/icons/Message";
import AddIcon from "@material-ui/icons/Add";
import { getTranslation } from "../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
  editButton: {},
  deleteButton: {
    color: theme.palette.error.dark,
  },
  infoButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  createButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light,
  },
  successButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,
  },
}));

export const EditButton = ({ onClick }) => {
  return (
    <Button size="small" variant="outlined" color="secondary" onClick={onClick}>
      <EditIcon />
    </Button>
  );
};

export const DeleteButton = ({ onClick,loading }) => {
  const classes = useStyles();
  return (
    loading ? <CircularProgress size={24} thickness={3} /> :
    <IconButton
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onClick}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export const CancelButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button aria-label="cancel" onClick={onClick}>
      {getTranslation("Cancel", "Annuler", "Absagen")}
    </Button>
  );
};

export const MessageButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.infoButton}
      size="small"
      variant="contained"
      onClick={onClick}
    >
      <MessageIcon />
      {children}
    </Button>
    //   <IconButton aria-label="delete" className={classes.deleteButton}>
    //   <DeleteIcon fontSize="small" />
    // </IconButton>
  );
};
export const PrintButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.infoButton}
      size="small"
      variant="contained"
      onClick={onClick}
    >
      <PrintIcon />
      {children}
    </Button>
    //   <IconButton aria-label="delete" className={classes.deleteButton}>
    //   <MessageIcon fontSize="small" />
    // </IconButton>
  );
};

export const CreateButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.createButton}
      size="small"
      variant="contained"
      onClick={onClick}
    >
      <AddIcon />
      {children}
    </Button>
    //   <IconButton aria-label="delete" className={classes.deleteButton}>
    //   <MessageIcon fontSize="small" />
    // </IconButton>
  );
};

export const DownloadButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    //   <Button
    //   className={classes.infoButton}
    //   size="small"
    //   variant="contained"
    //   onClick={onClick}
    // >
    //   Download
    //   <DownloadIcon />
    // </Button>
    <IconButton className={classes.successButton} onClick={onClick}>
      <FileDownloadIcon />
    </IconButton>
  );
};

export const TryAgainButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onClick}
    >
      <RepeatIcon fontSize="small" />
    </IconButton>
  );
};
