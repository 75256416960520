 import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useEffect } from "react";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import styles from "../../../../assests/styles/views/customerView/market/viewPackage";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";

export const ViewPackageCard = ({ packageItem, onSubmitPayment,isBusy,isUpgrade,customerApp }) => {
  const classes = styles();
  const [packagePeriod, setPackagePeriod] = useState();
  const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setPackagePeriod(packageItem.isMonthly === true ? 0 :packageItem.isYearly === true ? 1 : 2);
  }, [packageItem]);
  return (
    <Card style={{height:'700px'}}>
      <CardHeader
        className={classes.header}
        title={packageItem.title}
        titleTypographyProps={{
          style: {
            width: "100%",
            textAlign: "center",
            fontSize: "20px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            fontWeight: "bold",
          },
        }}
      />
      <CardContent>
        <ToggleButtonGroup
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size="small"
          value={packagePeriod}
          exclusive
          onChange={(e, period) => {
            if (period !== null) setPackagePeriod(period);
          }}
        >
          {packageItem.monthlyPrice > 0 ? isUpgrade && customerApp?.packagePaymentType==0 && customerApp?.packageId==packageItem.id ? null : (
            <ToggleButton value={0} arial-label="left aligned" style={{
              height:'50px'
              }}>
              <Typography>{packageItem.monthlyPrice.toFixed(2)} &euro; {getTranslation("Monthly","Mensuelle","Monatlich")}</Typography>
            </ToggleButton>
          ) : null}
          {packageItem.yearlyPrice > 0 ? isUpgrade && customerApp?.packagePaymentType==1 && customerApp?.packageId==packageItem.id ? null : (
            <ToggleButton value={1} arial-label="left aligned" style={{
              height:'50px'
              }}>
              <Typography>{packageItem.yearlyPrice.toFixed(2)} &euro; {getTranslation("Yearly","J�hrlich","Startseite")} </Typography>
            </ToggleButton>
          ) : null}
          {packageItem.lifeTimePrice > 0 ? isUpgrade && customerApp?.packagePaymentType==2 && customerApp?.packageId==packageItem.id ? null : (
            <ToggleButton value={2} arial-label="left aligned" style={{
              height:'50px'
              }}>
              <Typography >
                {packageItem.lifeTimePrice.toFixed(2)} &euro; {getTranslation("LifeTime","Lebenslange","Lebenslange")}
              </Typography>
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <br />
          {packageItem.trialDays > 0 ? (
            <Typography variant="body1">
             {getTranslation(" TEST for FREE -","TESTEZ GRATUITEMENT -","KOSTENLOS TESTEN -")}{packageItem?.trialDays} {getTranslation("Days","Jours","Tage")}
            </Typography>
          ) : (
            <>
            <br />
            </>
          )}
          <br />
          <br />
        </Box>
        <Box width="100%" overflow="hidden" mt={2}>
          <Typography className={classes.description}>
            {packageItem.description}
          </Typography>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box>
          <Typography variant="h6">{getTranslation("Features","Caract�ristiques","Merkmale")}</Typography>
          <Box className={classes.features}>
            {ReactHtmlParser(packageItem.features)}
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Box width="100%" display="flex" justifyContent="center">
          <ButtonWithLoading
          loading={isBusy}
            onClick={() => {
              onSubmitPayment(packageItem, packagePeriod);
            }}
            size="small"
            color={"secondary"}
            variant={"contained"}
            label={ isUpgrade ? getTranslation("Upgrade Now","Upgrade Now","Upgrade Now") :
              packageItem?.lifeTimePrice==0 && packageItem?.yearlyPrice==0 && packageItem?.monthlyPrice==0 ?
              getTranslation("Start for free","Start for free","Start for free") :
              packageItem?.trialDays > 0 ? 
              getTranslation("Start Free Trial Now","Start Free Trial Now","Start Free Trial Now") : getTranslation("Buy now","Buy now","Buy now")}
          >
            
          </ButtonWithLoading>
          
        </Box>
      </CardActions>
    </Card>
  );
};
