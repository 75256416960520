import React from 'react'
import {BrowserRouter} from "react-router-dom";
import Auth from "./components/Auth";
import Routes from "./routes";
import {SnackbarProvider} from "notistack"

const App = () => {
  
    return (
      <React.Fragment>
        <BrowserRouter>
                  <Routes />
        </BrowserRouter>
      </React.Fragment>
    );
}

export default App
