import axios from "axios";
import {URL} from "../constants";
import authService from "../utils/authUtils"


const instance = (history = null) => {
  let headers = {};
  if (authService.isAuthenticated()) {
    headers.Authorization = `Bearer ${authService.getUserToken()}`;
  }
  const axiosInstance = axios.create({
    baseURL: URL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
   { 
     return new Promise((resolve, reject) => resolve(response))
    },
    (error) => {
    
     return new Promise((resolve, reject) => {
       if (!error.response) {
        
           let err = {};
           err.response = {
             data: {
               value: "Network Error",
             },
           };
           // console.log("errorrrs", err);
           return reject(err);
      
       }
       if (error.response.status === 401) {
         authService.logout();
         history.push("/login");
       }
       if (error.response) {
         return reject(error);
       }
     });
    }
  );

  return axiosInstance;
};


export default instance