import axios from "../heplers/apiHelper";
import authUtils from "../utils/authUtils";
import { SET_USER, SET_USER_BY_ID } from "./types";
import { validateAuthentication } from ".";
import authService from "../utils/authUtils";
//End Points
const LoginEndPoint = "Auth/Login";
const Post_Logout_URL = "Auth/Logout";
const RefreshTokenEndPoint = "Auth/RefreshToken";
// const RegisterEndPoint = "Customers/RegisterCustomer";
const GetUserDataEndPoint = "Customers/GetCustomer";
const ForgrPasswordEndPoint = "Auth/ForgetPassword";
const ResetPasswordEndPoint = "Auth/ResetPassword";

export const userLogin = (payload) => async (dispatch) => {
  try {
    const response = await axios().post(LoginEndPoint, payload);
    return response;
  } catch (error) {
    console.log("ajklsdghasjkdhajklshdasjd",error)
    return error.response;
  }
};

export const tryGetRefreshToken = async (axiosRefreshInstance, payload) => {
  try {
    const response = await axios().post(`${RefreshTokenEndPoint}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const registerUser = (payload) => async (dispatch) => {
  try {
  } catch (error) {
    return;
  }
};

export const getUserData = (onSuccess, onError) => async (dispatch) => {

  await validateAuthentication("get", async (token) => {
    try {
      const response = await axios(token).get(GetUserDataEndPoint);
      
      onSuccess(response);
    } catch (error) {
   
      return onError(error.response);
    }
  });
  // const response = await axios(history).get(GetUserDataEndPoint);
  // dispatch({
  //   type: SET_USER,
  //   payload: response.data,
  // });
  // return response;
};

export const setUser = (user) => async (dispatch) => {
  authUtils.setSession(user.accessToken);
  dispatch({
    type: SET_USER,
    payload: user.user,
  });
};

export const refreshToken = async (token) => {
  try {
    // const response = await axios().post(RefreshTokenEndPoint,{token});
  } catch (error) {
    return error.response.data;
  }
};

export const forgetPassword = (payload) => async (dispatch) => {
  try {
    const response = await axios().post(ForgrPasswordEndPoint, payload);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const Logout = (history) => async (dispatch) => {
  try {
    const response = await axios().post(Post_Logout_URL);
    dispatch({ type: "LOGOUT" });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    const response = await axios().post(ResetPasswordEndPoint, payload);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
