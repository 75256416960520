import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  typography: {
    fontFamily: `"Roboto"`,
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    background: {
      dark: "#F4F6F8",
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "rgb(250,137,0)",
      light: "#55AE2",
      dark: "rgb(19 68 102)",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "rgb(7,68,100)",
      dark: "#030E31",
      light: "#0A2A93",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#6855f1",
    },
    radio: {
      main: "#ffffff",
      dark: "#ffffff",
      light: "#ffffff",
      contrastText: "#ffffff",
    },
  },
  shadows,
  typography,
});

theme.props = {
  MuiButton: {
    disableElevation: true,
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: "none",
    },
  },
  MuiIconButton: {
    root: {
      padding: "2px",
    },
  },
};

export default theme;
