import { PACKAGES_DATA, PACKAGES_LOADING, PACKAGES_ERROR ,PACKAGES_EDIT,PACKAGE_DATA} from "../actions/types";
const initState = {
  loading: true,
  errors: null,
  packages: [],
  editPackage:null,
  packageData:null
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case PACKAGES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case PACKAGES_DATA:
      return {
        ...state,
        loading: false,
        errors: null,
        package: action.payload,
      };
    case PACKAGE_DATA:
      return {
        ...state,
        loading: false,
        errors: null,
        packageData: action.payload,
      };
    case PACKAGES_ERROR:
      return {
        ...state,
        packages: [],
        loading: false,
        errors: action.payload,
      };
    case PACKAGES_EDIT:
      return {
        ...state,
        editPackage: action.payload,
      };
    default:
      return { ...state };
  }
};

export default userReducer;

