import { Box, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Get, Post } from "../../../actions";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Get_AllCustomersList_URL, Get_AllPackagesOfApp_URL, Get_AppsList_URL, Get_Apps_URL, Post_AddCustomOrganizations_URL } from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CardComponent from "../../../components/Card";

export const AddOrganizations = ({customers,apps}) => {
 

    const [packages, setPackages] = useState([]);
    const [loadPackages, setLoadPackages] = useState(false);
    const {enqueueSnackbar} =useSnackbar()
    const getPackages = (appId) => {
        setLoadPackages(true)
        Get(
            { appId },
            Get_AllPackagesOfApp_URL,
            null,
            resp => {
                setPackages(resp.data)
                setLoadPackages(false)
            },
            error => {

            }
        )
    }

    const defaultValue = {
        customerId: "",
        customer: null,
        packageId: 0,
        package: null,
        quantity: 0,
        app: {},
        totalNet:0,
        discount:0,
        packagePaymentType:0
    }
    const basicValidationSchema = Yup.object().shape({
        customerId: Yup.string().required("Required"),
        packageId: Yup.number().min(1,"Required"),
        quantity: Yup.number().min(1,"Required"),
        // discount: Yup.number().min(1,"Required"),
        // totalNet: Yup.number().min(1,"Required"),

      });



    const handleSubmit = (values, setSubmitting,resetForm) => {
        Post(
            values,
            Post_AddCustomOrganizations_URL,
            null,
            resp => {
                enqueueSnackbar("Request sent to customer for checkout.",{variant:"success"})
                setSubmitting(false)
                resetForm();
            },
            error => {
                enqueueSnackbar("System was not able to complete your request.",{variant:"error"})

                setSubmitting(false)
            }
        )
    }

    return <Formik
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}

        onSubmit={(values, actions) => {
            
            actions.setSubmitting(true)
            handleSubmit(values,actions.setSubmitting,actions.resetForm)
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldProps,
            isValid
        }) => (
            <Form>
                {console.log("kalsdjkasjdkasjdla",isValid)}
                <CardComponent title="Add Organizations" >
                    
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <Autocomplete
                                    value={values.customer}
                                    options={customers}
                                    size="small"
                                    onChange={(e,value) => {
                                        if(value){
                                        setFieldValue("customer",value)
                                        setFieldValue("customerId",value.id)
                                    }
                                    }}
                                    getOptionLabel={(option) => option ? option.name+" | "+option.email : ""}
                                    //   style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={touched.customerId && Boolean(errors.customerId)}
                                            label={getTranslation("Select Customer","Select Customer","Select Customer")}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <Autocomplete
                                    value={values.app}
                                    options={apps}
                                    size="small"
                                    onChange={(e, app) => {
                                        if(app){
                                            setFieldValue("app", app)
                                            setFieldValue("package", null)
                                            getPackages(app.id)
                                        }
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    //   style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={touched.app && Boolean(errors.app)}
                                            label={getTranslation("Select App","Select App","Select App")}
                                            />
                                    )}
                                />
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>

                                <Autocomplete
                                    disabled={packages.length == 0}
                                    value={values.package}
                                    options={packages}
                                    size="small"
                                    onChange={(e, value) => {
                                        if (value) {
                                            setFieldValue("package", value)
                                            if(value.isMonthly)
                                                setFieldValue("packagePaymentType", 0)
                                            else if (value.isYearly)
                                                setFieldValue("packagePaymentType", 1)
                                            else if (value.isLifeTime)
                                                setFieldValue("packagePaymentType", 2)
                                            setFieldValue("packageId", value.id)
                                            let amount=0;
                                            if(values.packagePaymentType==0)
                                                amount=value.monthlyPrice
                                            else if (values.packagePaymentType == 1)
                                                amount=value.yearlyPrice
                                            else if (values.packagePaymentType == 2)
                                                amount = value.lifeTimePrice
                                            const total = amount * values.quantity;
                                            setFieldValue("totalNet", total - ((values.discount / 100) * total))
                                        }
                                    }}
                                    getOptionLabel={(option) => option.title}
                                    //   style={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            error={touched.package && Boolean(errors.package)}
                                            label={getTranslation("Select Package", "Select Package", "Select Package")}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth size="small" variant="outlined" >
                                    <InputLabel id="demo-select-small">Select Period</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-select-small"
                                        value={values.packagePaymentType}
                                        disabled={values.package == null}
                                        label="Select Period"
                                        onChange={(e)=>{
                                             setFieldValue("packagePaymentType", e.target.value)
                                             const type=e.target.value
                                             let amount=0;
                                             if(type==0)
                                                 amount=values.package.monthlyPrice
                                             else if (type == 1)
                                                 amount=values.package.yearlyPrice
                                             else if (type == 2)
                                                 amount = values.package.lifeTimePrice
                                             const total = amount * values.quantity;
                                             setFieldValue("totalNet", total - ((values.discount / 100) * total))
                                        }}
                                    >
                                        {values.package?.isMonthly ? <MenuItem value={0} >{getTranslation("Monthly")} | {values.package?.monthlyPrice} €</MenuItem> : null}
                                        {values.package?.isYearly ? <MenuItem value={1} >{getTranslation("Yearly")} | {values.package?.yearlyPrice} €</MenuItem> : null}
                                        {values.package?.isLifeTime ? <MenuItem value={2} >{getTranslation("Life time")} | {values.package?.lifeTimePrice} €</MenuItem> : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <TextField
                                disabled={values.package == null}
                                    fullWidth
                                    id="quantity"
                                    type="number"
                                    label={getTranslation("Quantity", "Quantity", "Quantity")}
                                    size="small"
                                    variant="outlined"
                                    name="quantity"
                                    onChange={(e)=>{
                                        setFieldValue("quantity",e.target.value)
                                        const type=values.packagePaymentType
                                        let amount=0;
                                        if(type==0)
                                            amount=values.package.monthlyPrice
                                        else if (type == 1)
                                            amount=values.package.yearlyPrice
                                        else if (type == 2)
                                            amount = values.package.lifeTimePrice
                                        const total=amount*e.target.value;
                                        setFieldValue("totalNet", total - ((values.discount /100)*total))                                            
                                    }}
                                    error={touched.quantity && Boolean(errors.quantity)}
                                    helperText={touched.quantity && errors.quantity}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <TextField
                                    disabled={values.package == null}
                                    fullWidth
                                    id="discount"
                                    inputProps={{
                                        maxLength: 13,
                                        step: ".01"
                                      }}
                                    type="number"
                                    label={getTranslation("Discount", "Discount", "Discount") + " ( % )"}
                                    size="small"
                                    variant="outlined"
                                    name="discount"
                                    onChange={(e) => {
                                        setFieldValue("discount", e.target.value)
                                        const type=values.packagePaymentType
                                        let amount=0;
                                        if(type==0)
                                            amount=values.package.monthlyPrice
                                        else if (type == 1)
                                            amount=values.package.yearlyPrice
                                        else if (type == 2)
                                            amount = values.package.lifeTimePrice
                                        const total = amount * values.quantity;
                                        setFieldValue("totalNet", total - ((e.target.value / 100) * total))
                                    }}
                                    error={touched.discount && Boolean(errors.discount)}
                                    helperText={touched.discount && errors.discount}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sx={12} sm={12} md={4} lg={4}>
                                <TextField
                                disabled={true}
                                fullWidth
                                    id="totalNet"
                                    label={getTranslation("Total Net", "Total Net", "Total Net")}
                                    size="small"
                                    variant="outlined"
                                    name="totalNet"
                                    {...getFieldProps("totalNet")}
                                    error={touched.totalNet && Boolean(errors.totalNet)}
                                    helperText={touched.totalNet && errors.totalNet}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {console.log("jhaskdasghkdhdg",errors,isValid)}
                        <Box
                           width="100%"
                           display="flex"
                           alignItems="center"
                           justifyContent="center"
                           my={5}
                        >
                            <ButtonWithLoading
                                color="primary"
                                label={getTranslation(
                                    "Send Custom Organization Request",
                                    "Send Custom Organization Request",
                                    "Send Custom Organization Request"
                                )}
                                loading={isSubmitting}
                                type="submit"
                                variant="contained"
                                disabled={isValid==false || values.quantity<=0 || isSubmitting}
                            />
                        </Box>
                       
                    </CardContent>
                </CardComponent>

            </Form>
        )}
    </Formik>
}